/* eslint-disable */
import React, {createRef} from 'react'
import './Home.scss'
import LazyLoad from 'react-lazyload';
import img1 from '../../assets/images/iphone-img2.png'
import img2 from '../../assets/images/iphone-img-test.svg'
import img3 from '../../assets/images/iphone-img-dashboard.svg'
import img4 from '../../assets/images/iphone-img-thc.svg'
import img5 from '../../assets/images/iphone-img-cbd.svg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Hero from "./Hero/Hero";
import {Link} from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import {isMobile} from 'react-device-detect'
import ExploreKitCTAContainer from "../../components/Shared/ExploreKitCTAContainer";
import unique from "../../assets/images/Homepage_woman_tablet_570_560.jpg";
import uniquex2 from "../../assets/images/Homepage_woman_tablet_1140_1120.jpg";
import Helmet from "react-helmet";

class Home extends React.Component {
  scrollToUniqueSection = createRef()
  scrollToUniqueSectionMobile = createRef()

  scrollSmoothHandler = () => {
    if (isMobile)
      this.scrollToUniqueSectionMobile.current.scrollIntoView({behavior: 'smooth'})
    else
      this.scrollToUniqueSection.current.scrollIntoView({behavior: 'smooth'})
  }

  constructor(props) {
    super(props);

    this.scrollToUnique = this.scrollToUnique.bind(this);

  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Lobo Genetics Inc.</title>

          <meta itemProp="name" content="Lobo Genetics Inc."/>
          <meta itemProp="description" content="Our cannabis DNA test helps you understand how your body will react to THC and CBD. Discover your cannabis genetic profile now."/>
          <meta itemProp="image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="description" content="Our cannabis DNA test helps you understand how your body will react to THC and CBD. Discover your cannabis genetic profile now."/>
          <meta property="og:title" content="Lobo Genetics Inc."/>
          <meta property="og:description"
                content="Our cannabis DNA test helps you understand how your body will react to THC and CBD. Discover your cannabis genetic profile now."/>
          <meta property="og:url" content="https://www.lobogene.com"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="twitter:title" content="Lobo Genetics Inc."/>
          <meta name="twitter:description"
                content="Our cannabis DNA test helps you understand how your body will react to THC and CBD. Discover your cannabis genetic profile now."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        {this.renderHero()}
        {this.renderUnique()}
        {this.renderSteps()}
        {this.renderKitCTA()}
        {this.renderFAQ()}
      </>
    )
  }

  scrollToUnique() {
    this.scrollSmoothHandler()
  }

  renderHero() {
    return (<Hero scroll={this.scrollToUnique}/>)
  }
  renderUnique() {
    return (
        <section ref={this.scrollToUniqueSection} id="unique">
          <div ref={this.scrollToUniqueSectionMobile} className='position-relative' style={{top: -125}}></div>
            <div className="container">

              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="col-md-12 text-center wow">
                  <div className="title d-inline-block">
                    <h2 className="mb-md-3">Your response to cannabis is as unique as you are
                    </h2>
                  </div>
                </div>
              </ScrollAnimation>
              <div className="row align-items-center">
                <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="col-md-6 order-md-2 wow ">

                  <div className="card-content-text">
                    <h2>Learn more about yourself with our Cannabis Genetic Test.</h2>
                    <ul className="card-text">
                      <li>Understand your sensitivity and metabolism for THC and CBD
                      </li>
                      <li>Explore your mental health risk factors with THC use
                      </li>
                      <li>Discover how THC can affect your memory
                      </li>
                      <li><Link to="/cannabis-test" className="btn btn-gradient btn-rounded btn-large">Learn about Cannabis Genetic Test</Link>
                      </li>

                    </ul>

                  </div>

                </ScrollAnimation>
                <div className="col-md-6">

                  <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="card-content-image wow hover-effect ">
                    <img src={unique} srcSet={uniquex2}
                         focusable="false" role="img" alt=""/>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </section>

    )
  }


  renderSteps() {
    return (
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <section id="app" className="bg-light">
          <div className="container">

            <div className="row container">
              <div className="title d-inline-block">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <h2 className="mb-md-3 text-center fadeInUp">A few simple steps with results on your smartphone</h2>
                </ScrollAnimation>
              </div>
            </div>
            <div className="row align-items-center text-center">

              <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="col-md-4 wow ">

                <div className="app-feature">
                  <i className="fa fa-shopping-cart gradient-text1" aria-hidden="true"></i>
                  <h4 className="mb-3">Order</h4>
                  <p>Your kit is shipped directly to your home.</p>
                </div>

                <div className="app-feature">
                  <i className="fa fa-desktop gradient-text1" aria-hidden="true"></i>
                  <h4 className="mb-3">Register</h4>
                  <p>Register your kit online to get started.</p>
                </div>
              </ScrollAnimation>


              <ScrollAnimation animateIn="fadeInUpCard" animateOnce={true} className="col-md-4 wow">

                <div className="app-image">
                  <img src={img1} alt="image"  />
                  <OwlCarousel dots={false} loop id="app-slider" items={1} className="owl-carousel owl-theme h-100" autoplay={true}
                               height={500}>
                    <div className="item">
                      <img src={img2} alt=""/>
                    </div>
                    <div className="item">
                      <img src={img3} alt=""/>
                    </div>
                    <div className="item">
                      <img src={img4} alt=""/>
                    </div>
                    <div className="item">
                      <img src={img5} alt=""/>
                    </div>
                  </OwlCarousel>
                </div>
              </ScrollAnimation>

              <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="col-md-4 wow">

                <div className="app-feature">
                  <i className="fa fa-user gradient-text1" aria-hidden="true"></i>
                  <h4 className="mb-3">Swab</h4>
                  <p>Swab your cheek and return sample.</p>
                </div>

                <div className="app-feature">
                  <i className="fa fa-file-text-o gradient-text1" aria-hidden="true"></i>
                  <h4 className="mb-3">Explore</h4>
                  <p>Discover and explore your results.</p>
                </div>
              </ScrollAnimation>

            </div>
          </div>
        </section>
      </ScrollAnimation>
    )
  }


  renderKitCTA() {
    return (
      <div>
        <section id="pricing">
          <div className="container">
            <div className="row">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="col-md-12 text-center wow">
                <div className="title d-inline-block">
                  <h2 className="mb-md-6">Start exploring your cannabis profile today</h2>
                </div>
              </ScrollAnimation>
            </div>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="row justify-content-center wow">
              <ExploreKitCTAContainer cannabis/>
            </ScrollAnimation>
          </div>
        </section>
      </div>
    )
  }

  renderFAQ() {
    return (
      <div>
        <section id="faq">
          <div className="container">

            <div className="row">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                <div className="title d-inline-block">
                  <h2 className="mb-md-3">Frequently Asked Questions</h2>
                </div>
              </ScrollAnimation>
            </div>

            <section className="accordion pt-2 pb-1" id="accordionReportPages">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                      Who can use your service?
                    </button>
                  </h5>
                </div>
                <div id="collapse0" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <p>Our service is available in Canada to individuals at least 19 years old (18 in Alberta). We do not
                      service residents of
                      Quebec at this time. We also ship to individuals in the continential U.S. at least 21 years old (excludes Alaska, Hawaii and the
                      U.S. Protectorates). For international orders, please contact us at <a
                        href="mailto:info@lobogene.com">info@lobogene.com</a>.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                      How is my privacy protected?
                    </button>
                  </h5>
                </div>
                <div id="collapse1" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <p>We only examine a few relevant genes applicable to your test. Your DNA sample is
                      destroyed as part of the testing process.</p>
                    <p>We do not store or archive your DNA sample in any way</p>
                    <p>We will tell you how we collect, use and store your information</p>
                    <p>You may request deletion of your account and information at any time</p>
                    <p>We will not sell, lease, rent, or share your individual personal information to any third
                      party without your explicit consent</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                      How accurate and relevant are my results?
                    </button>
                  </h5>
                </div>
                <div id="collapse2" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <p>Our genetic testing service uses gold-standard Polymerase Chain Reaction (PCR)
                      technology. Your
                      sample is handled and processed at our own testing facility, in a controlled
                      environment. Our team
                      of scientists and experts have done extensive research to develop personalized insights
                      and reports
                      for the various tests and services we offer.</p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                            data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                      How long does the testing process take?
                    </button>
                  </h5>
                </div>
                <div id="collapse3" className="collapse" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <p>You should receive your swab kit within a few days of placing your order. We've included
                      a prepaid
                      return envelope with expedited shipping for your sample.</p>
                    <p>Your test is processed in Lobo’s state-of-the-art testing facility in Toronto, Canada
                      with results
                      delivered directly to your smartphone, typically within 1–2 business days of us
                      receiving your sample.
                      For orders outside of Canada, the time to receive your results may increase due to
                      shipping and logistics.</p>
                  </div>
                </div>
              </div>
              <section id="seeallfaq">
                <div className="container">

                  <ScrollAnimation animateIn="fadeIn" className="col-md-12 text-center wow">
                    <div className="title d-inline-block">
                      <h2>Still have questions?</h2>
                    </div>
                  </ScrollAnimation>

                  <div className="text-center">
                    <Link to="/faq" className="btn btn-gradient btn-rounded btn-large">Read our full list of FAQs</Link>
                  </div>
                </div>
              </section>
            </section>

          </div>
        </section>
      </div>
    )
  }
}

export default Home
