/* eslint-disable */
import React from 'react'
import logo from "../../assets/images/logo-header.png";
//import logowhite from "../../assets/images/LOBOgenetics_white_high_res.png";
import {Link} from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 220) { // Set position from top to add class
        $('header').addClass('header-appear');
      } else {
        $('header').removeClass('header-appear');
      }
    });

    if ($("#sidemenu_toggle").length) {
      $("#sidemenu_toggle").on("click", function () {
        $(".pushwrap").toggleClass("active");
        $(".side-menu").addClass("side-menu-active"), $("#close_side_menu").fadeIn(700)
      }), $("#close_side_menu").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $(this).fadeOut(200), $(".pushwrap").removeClass("active")
      }), $(".side-nav .navbar-nav .nav-link").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      }), $("#btn_sideNavClose").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      }), $("#btn-signout").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      }), $("#btn-signup").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      }), $("#btn-register").on("click", function () {
        $(".side-menu").removeClass("side-menu-active"), $("#close_side_menu").fadeOut(200), $(".pushwrap").removeClass("active")
      });

      $('.navbar .dropdown').hover(function() {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();
      }, function() {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp()
      });
    }

    if ($(".side-right-btn").length) {

      $(".side-right-btn").click(function () {
        $(".navbar.navbar-right").toggleClass('show');
      }),
        $(".navbar.navbar-right .navbar-nav .nav-link").click(function () {
          $(".navbar.navbar-right").toggleClass('show');
        });

    }
  }

  render() {
    return (
      <header>
        {this.renderNav()}
        {this.renderSideBar()}
      </header>
    )
  }

  renderNav() {
    return (
      <header>
        <nav className={this.getNavClass()}>
          <div className="container px-1 px-md-3">
            <Link to="/" title="Logo" className="logo scroll">
              <img src={this.getLogo()} alt="logo" className="logo-dark default mr-5"/>
            </Link>


            <div className="collapse navbar-collapse" id="halugen-nav">
              <div className="navbar-nav w-100 alt-font">
                <Link className={"nav-link " + this.isActiveNav("/cannabis-test")} to="/cannabis-test">Our Test</Link>
                <Link className={"nav-link " + this.isActiveNav("/how-it-works")} to="/how-it-works">How It Works</Link>
                <Link className={"nav-link " + this.isActiveNav("/about")} to="/about">About Us</Link>
                <Link className={"nav-link " + this.isActiveNav("/contact")} to="/contact">Contact</Link>
                <span className="menu-line"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
              </div>
            </div>

            <div className='d-flex align-items-center'>
            <Link to="cart" className="d-inline-block " id="">
              <i className={"fa fa-shopping-cart " + this.getCartColor()} style={{fontSize:24}} aria-hidden="true"></i>
              <span className='badge badge-danger rounded-circle position-relative' style={{fontSize:12, top:-10, right:5, paddingBottom: '1px', paddingLeft:'-1px'}}
                    id='lblCartCount'>
               {this.props.cart.cart.thcItemCount + this.props.cart.cart.cbdItemCount}
              </span>
            </Link>

            <a href="javascript:void(0)" className="d-inline-block sidemenu_btn" id="sidemenu_toggle">
              <span></span>
              <span></span>
              <span></span>
            </a>
            </div>

          </div>
        </nav>
      </header>
    )
  }

  renderUserCountry() {
    if (this.props.userCountry === 'Canada')
      return (
        <img src={require('../../assets/images/ca.png')} style={{height: 20}}/>
      )

    if (this.props.userCountry === 'United States')
      return (
        <img src={require('../../assets/images/us.png')} style={{height: 20}}/>
      )

    return null;
  }

  modifyUserCountry(country) {
    if(country === this.props.userCountry)
      return;

    this.props.modifyUserCountry(country);
  }

  isActiveNav(str) {
    if (window.location.href.includes(str))
      return "active";
    return ""
  }

  getCartColor() {
    if (this.props.format && this.props.format === 'transparent-light') {
      return
    }

    if (this.props.format && this.props.format === 'other') {
      return
    }
  }

  getNavClass() {
    let home = 'navbar navbar-top-default navbar-expand-lg navbar-simple navbar-dark nav-box-round';
    let light = 'navbar navbar-top-default navbar-expand-lg navbar-simple navbar-dark nav-box-round';
    let other = 'navbar navbar-top-default navbar-expand-lg navbar-simple navbar-dark nav-box-round';

    if (this.props.format && this.props.format === 'transparent-light') {
      return light
    }

    if (this.props.format && this.props.format === 'other') {
      return other
    }
    return home
  }


  getLogo() {
    let home = logo;
    let light = logo;

    if (this.props.format && (this.props.format === 'transparent-light' || this.props.format === 'other')) {
      return light
    }

    return home
  }

  closeModal() {
    $(".navbar.navbar-right").toggleClass('show');
  }


  renderSideBar() {
    return (
      <>
        <div className="side-menu">
          <div className="inner-wrapper">
            <span className="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
            <nav className="side-nav w-100">

              <ul className="navbar-nav">
                {this.props.user.isLoggedIn ? <li className="nav-item">
                  <Link className="nav-link" to="/dashboard/overview">My Dashboard</Link>
                </li> : null}
                <li className="nav-item">
                  <Link className="nav-link" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/cannabis-test">Cannabis Genetic Test</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/how-it-works">How It Works</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">About Us</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contact</Link>
                </li>
              </ul>

              <div className="row justify-content-center mt-4">
                {this.props.user.isLoggedIn ?
                  <Link id={'btn-signout'} className="btn btn-signin mr-3" to="/" onClick={() => this.props.doLogout()}>Sign Out</Link>
                  : <Link id={'btn-signup'} className="btn btn-signin mr-3" to="/sign-in">Sign In</Link>}
                <Link id={'btn-register'} className="btn btn-registerkit" to="/register">Register Kit</Link>
              </div>

            </nav>

            <div className="side-footer  w-100">
              <ul className="social-icons-simple">
                <li>
                  <a className="" href="https://www.facebook.com/lobogenetics" target="_blank">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="https://www.instagram.com/lobogenetics" target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="https://twitter.com/lobogenetics" target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="https://www.linkedin.com/company/lobo-genetics" target="_blank">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="mailto:support@lobogene.com" target="_blank">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
              <p>&copy; 2021 Lobo Genetics Inc.</p>
            </div>
          </div>
        </div>
        <a id="close_side_menu" href="javascript:void(0);"></a></>
    )
  }
}

export default Header;
