import React from "react";
import ExploreKitCTA from "../../components/Shared/ExploreKitCTA";
import {Link} from "react-router-dom";
import Helmet from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";
import dna from '../../assets/images/our-tests-thc-image.jpg'
import share from '../../assets/images/share.png'
import share2x from '../../assets/images/share2x.png'




class AffiliateProgram extends React.PureComponent {

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Affiliate Program | Lobogene Inc.</title>

          <meta itemProp="name" content="Affiliate Program | Lobo Genetics Inc."/>
          <meta itemProp="description" content="Lobogene affiliate program allows you to refer visitors
                        to order Cannabis Genetic Test Kit and earn commission."/>
          <meta itemProp="image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="description" content="Lobogene affiliate program allows you to refer visitors
                        to order Cannabis Genetic Test Kit and earn commission."/>
          <meta property="og:title" content="Affiliate Program | Lobo Genetics Inc."/>
          <meta property="og:description"
                content="Lobogene affiliate program allows you to refer visitors
                        to order Cannabis Genetic Test Kit and earn commission."/>
          <meta property="og:url" content="https://www.lobogene.com/affiliate-program"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="twitter:title" content="Affiliate Program | Lobo Genetics Inc."/>
          <meta name="twitter:description"
                content="Lobogene affiliate program allows you to refer visitors
                        to order Cannabis Genetic Test Kit and earn commission."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        {this.renderHeader()}
        {this.renderHowItWorks()}
        {this.renderReasonwhy()}
        {this.renderPrivacy()}
        {this.renderExploreKitCTA()}
        {this.renderFAQ()}

      </>
    )
  }

  renderHeader() {
    return (
      <section className="bg-img-affiliate mt-0">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="title text-center">
                <p className="text-white mb-2">JOIN LOBOGENE'S AFFILIATE PROGRAM</p>
                <h2 className="text-white">Become an affiliate. Earn commissions.</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  renderHowItWorks() {
    return (
      <section id="works">
        <div className="container">

          <div className="row">
            <div className="col-md-12 text-center">
              <div className="title d-inline-block">
                <h2 className="gradient-text1 mb-md-3">How it works?</h2>
              </div>
            </div>
          </div>


          <ScrollAnimation animateIn="fadeInUpCard" animateOnce={true} className="wow ">
            <div className="row">
              <div className="col-lg-3 text-center">
                <div className="title d-inline-block">
                  <i className="gradient-text1 fa fa-2x mb-3 fa-file" aria-hidden="true"></i>

                  <h5 className="mb-md-3">1. Apply</h5>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="title d-inline-block">
                  <i className="gradient-text1 fa  fa-2x mb-3 fa-check-square" aria-hidden="true"></i>
                  <h5 className=" mb-md-3">2. Get Approved</h5>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="title d-inline-block">
                  <i className="gradient-text1 fa fa-2x mb-3 fa-share-alt" aria-hidden="true"></i>

                  <h5 className=" mb-md-3">3. Share</h5>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <div className="title d-inline-block">
                  <i className="gradient-text1 fa fa-2x mb-3 fa-dollar " aria-hidden="true"></i>
                  <h5 className="mb-md-4">4. Earn Commissions</h5>
                </div>
              </div>
            </div>

          </ScrollAnimation>



        </div>
      </section>
    )
  }

  renderPrivacy() {
    return (
        <div>
          <section id="privacy">
            <div className="container">

              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="col-md-12 text-center wow">
                  <div className="title d-inline-block">
                    <h2 className="gradient-text1 mb-md-3">How does the Lobogene Affiliate Program work?</h2>
                  </div>
                </div>
              </ScrollAnimation>
              <div className="row align-items-center">
                <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="col-md-6 order-md-2 wow ">

                  <div className="card-content-text">
                    <h2>Share and get rewarded</h2>
                    <ul className="card-text">
                      <li>Promote the Lobogene Cannabis Genetic Test on your website, podcast or social media and we will
                        compensate you for any referral sales. It’s that easy!
                      </li>
                      <li>We understand that your audience is valuable and we want to partner with you.
                        We offer a competitive, customizable compensation structure that will reward you for qualified referral sales.
                      </li>
                    </ul>
                    <a href=" https://halugen.tapfiliate.com/programs/lobogene-affiliate-program-ca/signup/" className="btn btn-gradient btn-rounded btn-large mt-3"> Apply to become an affiliate</a>
                  </div>

                </ScrollAnimation>
                <div className="col-md-6">

                  <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="card-content-image wow hover-effect ">
                    <img src={share} srcSet={share2x}
                         focusable="false" role="img" alt=""/>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
  }
  renderReasonwhy() {
    return (
      <div>
        <section id="tech-platform" className="bg-light">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="col-md-12 text-center wow">
              <div className="title d-inline-block">
                <h2 className="gradient-text1 mb-md-3">Help enable safer cannabis experiences through genetics</h2>
              </div>
            </ScrollAnimation>

            <div className="row align-items-center">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="col-md-6 wow">

                <div className="card-content-text">
                  <ul className="card-text">
                    <li>Lobo Genetics is a healthcare technology company with the goal of helping people make
                      informed choices around cannabis through
                      personalization.
                    </li>
                    <li>We believe in helping you better understand your response to cannabis through genetics, data and engagement.
                      Through our proprietary pre-screening platform,
                      users can gain rapid genetic insights from a simple cheek swab.
                    </li>
                    <li>If you have a network of people who might benefit from learning
                      more about our HaluGen Psychedelics Genetic Test,
                      we'd love to partner with you!
                    </li>
                  </ul>
                </div>
              </ScrollAnimation>
              <div className="col-md-6">

                <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="card-content-image text-right hover-effect wow ">
                  <img src={dna}
                       focusable="false" role="img" alt=""/>
                </ScrollAnimation>
              </div>
            </div>

          </div>
        </section>
      </div>
    )
  }
  renderFAQ() {
    return (
        <div>
          <section id="faq">
            <div className="container">

              <div className="row">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                  <div className="title d-inline-block">
                    <h2 className="gradient-text1 mb-md-3">Frequently Asked Questions</h2>
                  </div>
                </ScrollAnimation>
              </div>

              <section className="accordion pt-2 pb-1" id="accordionReportPages">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                        What is the Lobogene Affiliate Program?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse0" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Lobogene affiliate program allows you to refer visitors to the Lobogene website
                        to order Cannabis Genetic Test Kit. You earn a commission for any kit that
                        is purchased within 45 days after that person uses your link to access our site.
                        So in a nutshell, you share your unique affiliate link, and we pay you a commission from each
                        test kit sale from the visitors you send us!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                        How to sign up?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse1" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>To sign up for the Lobogene affiliate program <a style={{textDecoration: 'underline'}}
                                                                                       href="https://halugen.tapfiliate.com/programs/lobogene-affiliate-program-ca/signup/">click here</a> and create an account.
                      </p>
                      <p>We will review your application and once accepted, you will receive an e-mail notification with your approval confirmation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                        Is there any cost to become an affiliate?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse2" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>No, it’s a free program with no obligation, you can stop being an affiliate anytime.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                        Is marketing content available for me to share?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse3" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Yes, Lobogene provides affiliates with text links, banners, images, copy, and suggestions to
                        best introduce our cannabis genetic tests to your audience.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse4" aria-expanded="false" aria-controls="collapse3">
                        Do I get notified if my referred visitors buy a genetic test kit?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse4" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Yes you'll recieve an email notification. Also you can <a style={{textDecoration: 'underline'}}
                                                                                   href=" https://halugen.tapfiliate.com/programs/lobogene-affiliate-program-ca/signup/">log in</a> to your account to see tracking of your conversions, commissions and
                        links in real-time.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse5" aria-expanded="false" aria-controls="collapse3">
                        How do I get paid?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse5" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Payments are made directly via PayPal. If you had more questions, please contact us at <a
                          href="mailto:affiliates@lobogene.com">affiliates@lobogene.com</a>.
                      </p>
                    </div>
                  </div>
                </div>

              </section>
              <section id="applyforaffiliate">
                <div className="container">

                  <ScrollAnimation animateIn="fadeIn" className="col-md-12 text-center wow">
                    <div className="title d-inline-block">
                      <h2 className="gradient-text1">Partner with us</h2>
                    </div>
                  </ScrollAnimation>

                  <div className="text-center">
                    <a href=" https://halugen.tapfiliate.com/programs/lobogene-affiliate-program-ca/signup/" className="btn btn-gradient btn-rounded btn-large">Apply to become an affiliate</a>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
    )
  }

  renderExploreKitCTA() {
    return <ExploreKitCTA bg={'#ffffff'}/>
  }
}


export default AffiliateProgram
