import createReducer from "./createReducer";
import {localStorageGet, localStorageSet} from "../lib/localStorage-helper";
import {STORAGE_TIP_COUNTER} from "../lib/constants";
import {isNullOrUndefined} from "../lib/helper";


function fetchCounter() {
  let counter = Number.parseInt(localStorageGet(STORAGE_TIP_COUNTER));
  try {
    if (!isNullOrUndefined(counter) && !counter.isNaN() ) {
      localStorageSet(STORAGE_TIP_COUNTER, counter + 1 > 3 ? 0 : counter + 1)
      return counter;
    } else {
      localStorageSet(STORAGE_TIP_COUNTER, 1)
      return 0
    }
  } catch (e) {
    localStorageSet(STORAGE_TIP_COUNTER, 1)
    return 0
  }
}

export const TipsCounter = createReducer(fetchCounter(), {});
