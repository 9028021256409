import React from "react";
import {isNullOrUndefinedOrEmpty} from "../../lib/helper";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux'
import {ActionCreators} from "../../actions";
import {withRouter} from "react-router-dom";
import {getAkt1String, getMemoryString, getMetabolismString, renderCBDG} from "../../lib/genetics-helper";

function Summary(props) {
  const {cbdTestResults, thcTestResults, toPdf} = props;
  const thc = thcTestResults[0] || {};
  const cbd = cbdTestResults[0] || {};
  return <>
    <SingleResult data={getMetabolismString(thc.cyp2c9)} type="thcMetabolism" color="var(--genetics-primary)" /><hr />
    <SingleResult data={getAkt1String(thc.akt1)} type="thcRisk" color="var(--akt1-secondary)" /><hr />
    <SingleResult data={getMemoryString(thc.comt)} type="thcMemory" color="var(--comt-secondary)" /><hr />
    <SingleResult data={renderCBDG(cbd)} type="cbd" color="var(--genetics-tertiary)" />
  </>
}

function SingleResult(props) {
  const {data, color, type} = props;
  const dataPresent = !isNullOrUndefinedOrEmpty(data) && data.short !== '';
  const {short} = data;
  const dataInconclusive = short === 'Inconclusive';
  const dataPending = short === 'Pending';
  const typeText = {
    thcMetabolism: {
      title: "THC Metabolism",
      genotypeName: "CYP2C9",
      type: "THC",
      pdf: <a className="text-primary pointer" data-toggle="modal" data-target="#thc-metabolism-modal">View THC Metabolism Report&nbsp;<i className="fa fa-angle-right"/></a>,
    },
    thcRisk: {
      title: "Mental Health Risk",
      genotypeName: "ATK1",
      type: "THC",
      pdf: <a className="text-primary pointer" data-toggle="modal" data-target="#mental-health-modal">View Mental Health Risk Report&nbsp;<i className="fa fa-angle-right"/></a>,
    },
    thcMemory: {
      title: "Memory",
      genotypeName: "COMT",
      type: "THC",
      pdf: <a className="text-primary pointer" data-toggle="modal" data-target="#memory-modal">View Memory Report&nbsp;<i className="fa fa-angle-right"/></a>,
    },
    cbd: {
      title: "CBD Metabolism",
      genotypeName: "CYP2C19",
      type: "CBD",
      pdf: <a className="text-primary pointer" data-toggle="modal" data-target="#cbd-modal">View CBD Metabolism Report&nbsp;<i className="fa fa-angle-right"/></a>,
    },
  }
  return (
    <div className="row no-gutters" id="thc-metabolism-genetics-summary">
      { dataPresent ? <>
          <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
            <img className="mb-auto mr-3 p-3" src={require("../../assets/images/img-dna-helix-transp.png")} style={{width:'105px',background: color}}/>
            { dataInconclusive ? <InconclusiveDescription /> :
                dataPending ? <PendingDescription /> :
                  <ResultDescription data={data} color={color} text={typeText[type]} /> }
          </div>
          <div className="col d-none d-lg-block"><ResultData data={data} color={color} text={typeText[type]} /></div>
        </>
        : <div className="col-md-12 col-lg-12 flex-row mb-2"><NoData color={color} text={typeText[type]} /></div>
      }
    </div>
  )
}

function ResultDescription(props) {
  const {data, text} = props;
  const {long, short } = data || {};
  const {type, pdf} = text;
  return (
    <div>
      <h4>You are a <strong>{short}</strong> of {type}<br/></h4>
      <p>{long}<br/></p>
      {pdf}
    </div>
  )
}

function ResultData(props) {
  const {data, color, text} = props;
  const { genotype, short } = data || {};
  const {title, genotypeName} = text;
  return (
    <div id="thc-metabolism-genetics-summary-results">
      <div className="text-center mb-3">
        <h4 className="mb-2">{title}</h4>
        <h4 style={{color: color}}>{short}&nbsp;</h4>
      </div>
      <div className="text-center">
        <h4 className="mb-2">{genotypeName}</h4>
        <h4 style={{color: color}}>{genotype}</h4>
      </div>
    </div>
  )
}

function NoData(props) {
  const {color, text} = props;
  const {title, genotypeName, type} = text;
  return (
    <div className="row no-gutters">
      <div className="col col-md-6 col-lg-6 d-lg-block">
        <div className="row">
          <img className="mb-auto ml-3 p-3" src={require("../../assets/images/img-dna-helix-transp.png")} style={{width:'105px',background: color}}/>
          <div className="col d-none d-lg-block">
            <h4>You are a N/A metabolizer of {type}</h4>
            <div onClick={() => this.props.history.push('/register-kit')} data-dismiss="modal" className="text-primary pointer">Register your kit&nbsp;<i className="fa fa-angle-right"/></div>
          </div>
        </div>
      </div>
      <div className="col d-none d-lg-block">
        <div id="cbd-metabolism-genetics-summary-results">
          <div className="text-center mb-3">
            <h4 className="mb-2">{title}</h4>
            <h4 style={{color: color}}>No Result&nbsp;</h4>
          </div>
          <div className="text-center">
            <h4 className="mb-2">{genotypeName}</h4>
            <h4 style={{color: color}}>No Result</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

function PendingDescription() {
  return (
    <div>
      <h4>Pending</h4>
      <p>&nbsp;</p>
    </div>
  )
}

function InconclusiveDescription() {
  return (
    <div>
      <h4>Inconclusive</h4>
      <p>In a small number of cases, a sample produces an inconclusive result. This is usually from an insufficient amount of sample DNA or from interfering substances in the mouth. Please email <a href="mailto:%{email}"> support@lobogene.com</a> for your options.</p>
    </div>
  )
}

const mapStatetoProps = state => ({
  thcTestResults: state.ThcTestResults,
  cbdTestResults: state.CbdTestResults
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Summary));
