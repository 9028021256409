import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../../actions'
import ThcTest from "./ThcTest";


const mapStateToProps = state => ({
  product: state.THCProduct
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const ThcTestContainer = connect(mapStateToProps, mapDispatchToProps)(ThcTest);

export default ThcTestContainer
