import React from "react";
import ReactToPdf from "react-to-pdf";
import Summary from "../Summary/Summary";
import {bindActionCreators} from "redux";
import {ActionCreators} from "../../actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const options = {
  orientation: 'landscape'
};

class Pdf extends React.Component {
  ref = React.createRef();

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='container py-5'>
        <ReactToPdf targetRef={this.ref} filename="Lobo-Genetic-My-Report.pdf" x={.5} y={.5} scale={0.8}>
          {({toPdf}) => (
            <>
              <button onClick={toPdf}>Generate pdf</button>
              <p className='mt-1'>Open in browser for best results</p>
            </>
          )}
        </ReactToPdf>
        <div ref={this.ref} className='container py-5'>
          <h3 className='mb-1'>My Report Summary - Lobo Genetics</h3>
          <p className='mb-5'>{new Date().toLocaleDateString()}{' - '}
            {this.props.user.userProfile.fname + ' ' + this.props.user.userProfile.lname}</p>
          <Summary toPdf/>
        </div>
      </div>
    )
  }
}


const mapStatetoProps = state => ({
  user: state.LoginStatus
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Pdf));
