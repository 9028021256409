import React from "react";
import shrooms from '../../../assets/images/cannabis-bud.jpg'
import {Link} from "react-router-dom";
import {isNullOrUndefinedOrEmpty} from "../../../lib/helper";
import Helmet from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";
import img1 from "../../../assets/images/iphone-img2.png";
import OwlCarousel from "react-owl-carousel";

import img5 from "../../../assets/images/iphone-img-cbd.svg";

class CbdTest extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Cannabis Genetic Test | Lobo Genetics Inc.</title>

          <meta itemProp="name" content="Cannabis Genetic Test | Lobo Genetics Inc."/>
          <meta itemProp="description"
                content="Order your THC cannabis DNA test online and learn how your body reacts to cannabis with a personalized report."/>
          <meta itemProp="image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="description"
                content="Order your THC cannabis DNA test online and learn how your body reacts to cannabis with a personalized report."/>
          <meta property="og:title" content="Cannabis Genetic Test | Lobo Genetics Inc."/>
          <meta property="og:description"
                content="Order your THC cannabis DNA test online and learn how your body reacts to cannabis with a personalized report."/>
          <meta property="og:url" content="https://www.lobogene.com/cbd-test.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="twitter:title" content="Cannabis Genetic Test | Lobo Genetics Inc."/>
          <meta name="twitter:description"
                content="Order your THC cannabis DNA test online and learn how your body reacts to cannabis with a personalized report."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
        </Helmet>
      </>
    )
  }

  render() {
    if (isNullOrUndefinedOrEmpty(this.props.product)) {
      return (
        <div className='min-vh-100 d-flex justify-content-center align-items-center align-content-center'>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
    const productTitle = this.props.product.title;
    const descriptionHtml = this.props.product.descriptionHtml;
    const isAvailableForSale = this.props.product.availableForSale;
    const price = this.props.product.priceRange.maxVariantPrice.amount;
    const currency = this.props.product.priceRange.maxVariantPrice.currencyCode;
    const images = this.props.product.images.edges;
    return (
      <>
        {this.renderMetaTags()}
        <section id="test" className='mt-0 pb-0'>
          <div className="container">
            <section className="row py-5">
              <div className="col-md-6 col-lg-5 pb-4 pb-lg-0">
                <div id="carousel" className="carousel slide " data-interval="false" data-ride="carousel">
                  <div className="carousel-inner mb-3">
                    {!isNullOrUndefinedOrEmpty(images[0]) ?
                      <div className="carousel-item active">
                        <img className='img-fluid' src={images[0].node.src}/>
                      </div> : null}
                    {!isNullOrUndefinedOrEmpty(images[1]) ?
                      <div className="carousel-item">
                        <img src={images[1].node.src}/>
                      </div> : null}
                    {!isNullOrUndefinedOrEmpty(images[2]) ?
                      <div className="carousel-item">
                        <img src={images[2].node.src}/>
                      </div> : null}
                    {!isNullOrUndefinedOrEmpty(images[3]) ?
                      <div className="carousel-item">
                        <img src={images[3].node.src}/>
                      </div> : null}
                  </div>
                  <div className="clearfix">
                    <div className="carousel-link">
                      {!isNullOrUndefinedOrEmpty(images[0]) ?
                        <div data-target="#carousel" data-slide-to={0} className="thumb">
                          <img src={images[0].node.src}/>
                        </div>
                        : null}
                      {!isNullOrUndefinedOrEmpty(images[1]) ?
                        <div data-target="#carousel" data-slide-to={1} className="thumb">
                          <img src={images[1].node.src}/>
                        </div>
                        : null}
                      {!isNullOrUndefinedOrEmpty(images[2]) ?
                        <div data-target="#carousel" data-slide-to={2} className="thumb">
                          <img src={images[2].node.src}/>
                        </div>
                        : null}
                      {!isNullOrUndefinedOrEmpty(images[3]) ?
                        <div data-target="#carousel" data-slide-to={3} className="thumb">
                          <img src={images[3].node.src}/>
                        </div>
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1">
                <div className="card-content-text">
                  <h2 className="gradient-text1">{productTitle}</h2>
                  <div dangerouslySetInnerHTML={{__html: descriptionHtml}}></div>
                </div>
                <div className="price-tag d-flex">
                  <ul className="packages">
                    <li>
                      {!isAvailableForSale ?
                        <h6><i className="fa fa-times" aria-hidden="true"/>Out of stock</h6> :
                        <h6><i className="fa fa-check" aria-hidden="true"/>In stock</h6>}
                    </li>
                  </ul>
                </div>
                <ul className="d-flex justify-content-between align-items-center list-unstyled text-left my-4">
                  <li>
                    <h3 className="d-inline-block currency">{currency === 'CAD'? '' : ''}${Number.parseFloat(price).toFixed(0)}</h3>
                  </li>
                  <li>
                    <h5>Free Shipping</h5>
                  </li>
                </ul>
                {isAvailableForSale ?
                  <Link to='/cart' className={"btn btn-large btn-gradient mt-3"}
                        onClick={() => this.props.addProductToCart(1, 'cbd')}>Add to cart</Link>
                  : <div className={"btn btn-large btn-gradient mt-3 disabled"}>Add to cart</div>
                }
              </div>
            </section>

            <section className="accordion pt-2 pb-1" id="accordionReportPages">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse0"
                            aria-expanded="true" aria-controls="collapse0">
                      Metabolism Report
                    </button>
                  </h5>
                </div>
                <div id="collapse0" className="collapse show" aria-labelledby="headingOne"
                     data-parent="#accordionReportPages">

                  <div className="card-body">
                    <div className="row no-gutters">
                      <div className="col-lg-5">
                        <h5 className="card-title ">YOUR SENSITIVITY TO CBD</h5>
                        <p className="card-content-text pt-3">The way your body metabolizes CBD,
                          the active non-intoxicating compound in cannabis, is influenced by the CYP2C19 gene.
                          This gene encodes a liver enzyme that helps metabolize CBD in your bloodstream. 25% of the
                          population carries the gene variant that causes them to metabolize CBD more slowly than normal.
                          Conversely, 25% of people are fast metabolizers CBD.
                        </p>
                        <p className="card-content-text">Understanding your rate of CBD metabolism can help you better
                          understand the effects of CBD and how it functions in the body.</p>
                      </div>
                      <div className="col-lg-5 offset-lg-1 pt-4 pt-lg-0">
                        <h5 className="card-title ">METABOLISM REPORT INCLUDES
                        </h5>
                        <ul className="card-content-text">
                          <li className="">Insight into your speed of metabolism</li>
                          <li className="">Genetics by population and ethnicity</li>
                          <li className="">How different formats will affect you
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>

          </div>

        </section>


        <section id="benefits" className="bg-light">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <div className="col-md-12 text-center wow">
              <div className="title d-inline-block">
                <h2 className=" mb-md-3">Discover your sensitivity to CBD</h2>
              </div>
              </div>
            </ScrollAnimation>
            <div className="row align-items-center text-center">

              <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="col-md-4 d-none d-md-block">
                <div className="app-feature">
                </div>
                <div className="app-feature">
                </div>
              </ScrollAnimation>


              <ScrollAnimation animateIn="fadeInUpCard" animateOnce={true} className="col-md-4 wow d-lg-none pb-5">
                <div className="app-image">
                  <img src={img1} alt="image"  />
                  <OwlCarousel dots={false} loop id="app-slider" items={1} className="owl-carousel owl-theme h-100" autoplay={true}
                               height={500}>
                    <div className="item">
                      <img src={img5} alt=""/>
                    </div>
                  </OwlCarousel>
                </div>
              </ScrollAnimation>


            </div>
            <div className="row align-items-center">
              <div className="col-md-6">

              <ScrollAnimation animateIn="fadeInUpCard" animateOnce={true} className="col-lg-6 order-md-2 wow d-none d-lg-block">

                  <div className="app-image">
                    <img src={img1} alt="image"  />
                    <OwlCarousel dots={false} loop id="app-slider-test" items={1} className="owl-carousel owl-theme h-100" autoplay={true}
                                 height={500}>
                      <div className="item">
                        <img src={img5} alt=""/>
                      </div>
                    </OwlCarousel>
                  </div>
                </ScrollAnimation>
              </div>
              <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="col-lg-6 order-md-2 wow">

                <div className="card-content-text">
                  <h2>Explore your cannabis genetic profile, health tips, product recommendations and more.</h2>
                  <ul className="card-content-text">
                    <li>In addition to your reports, we're constantly updating our
                      platform to bring you future insights and benefits including:
                    </li>
                    <li>Tools and recommendations to better understand your cannabis profile</li>
                    <li>The latest science and research news</li>
                    <li>Exclusive offers from our partners</li>
                  </ul>
                </div>
              </ScrollAnimation>

            </div>
          </div>
        </section>


        <section id="learnmore-hiw">
          <div className="container">

            <ScrollAnimation animateIn="fadeIn" className="col-md-12 text-center wow">
              <div className="title d-inline-block">
                <h2>Want to know more?</h2>
              </div>
            </ScrollAnimation>

            <div className="text-center">
              <Link to="/how-it-works" className="btn btn-gradient btn-rounded btn-large">Learn How It Works</Link>
            </div>
          </div>
        </section>
      </>
    )
  }

}

export default CbdTest
