import React from "react";
import './Footer-Dark.scss'
import {Link} from "react-router-dom";
import GeneticsReportSummaryContainer from "./Summary/GeneticsReportSummaryContainer";
import bgImage from '../../assets/images/Background.png';
import {bindActionCreators} from "redux";
import {ActionCreators} from "../../actions";
import {connect} from "react-redux";

class FooterDashboard extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className='dashboard dashboard-footer'>
        <div id="genetics-modals">
          <GeneticsReportSummaryContainer/>
        </div>
        <div className="bg-primary footer-dark" style={{backgroundImage: `url(${bgImage})`}}>
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-3 item">
                  <h3 className='text-white'>Dashboard</h3>
                  <ul>
                    <li><Link id="overview-tab-js-footer-link" to={'/dashboard/overview'}>Overview</Link></li>
                    <li><Link id="genetics-tab-js-footer-link" to={'/dashboard/genetic'}>Genetic Reports</Link></li>
                    <li><Link id="research-papers-tab-js-footer-link" to={'/dashboard/learn'}>Learn</Link></li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-3 item">
                  <h3 className='text-white'>Quick Links</h3>
                  <ul>
                    <li><Link to={'/tutorial?test=cannabis'}>How to Swab</Link></li>
                  </ul>
                </div>
                <div className="col-md-6 item text">
                  <h3 className='text-white'>Lobo Genetics</h3>
                  <p className='text-white'>Our mission is to help people make informed choices around cannabis through
                    personalization with genetics, data and engagement.</p>
                </div>
                <div className=" col item social">
                  <a href="https://www.facebook.com/lobogenetics" target="_blank">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="https://twitter.com/lobogenetics" target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="https://www.instagram.com/lobogenetics" target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/lobo-genetics" target="_blank">
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a href="mailto:support@lobogene.com" target="_blank">
                    <i className="fa fa-envelope"></i>
                  </a>
                </div>
              </div>
              <p className="copyright text-white">Your use of our services and the information provided through this
                website, including test results and reports, is for informational purposes only, and is not intended for
                medical advice or treatment, or for other therapeutic or diagnostic use. Before taking any action based
                on this information, you must consult an experienced healthcare professional. Your use of our services
                and this website is governed by the laws of the Province of Ontario and the federal laws of Canada. ©
                {' ' + new Date().getFullYear()} Lobo Genetics Inc. All rights reserved.</p>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.LoginStatus
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(FooterDashboard);
