import React from 'react'
import './Footer.scss'
import {Link, withRouter} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import bgImage from "../../assets/images/Background.png";
import {bindActionCreators} from "redux";
import {ActionCreators} from "../../actions";
import {connect} from "react-redux";
import {toast, ToastContainer} from "react-toastify";

class Footer extends React.Component {
  constructor(props) {
    super(props);

  }


  render() {
    return (

      <section className="bg-primary text-center mb-0" style={{backgroundImage: `url(${bgImage})`}}>
        <div className="container">
          <ToastContainer/>

          <div className="container text-center text-md-left">
            <div className="row">

              <div className="col-md-3 col-lg-3 col-xl-3 mb-4 ">
                <h6 className="gradient-light text-uppercase font-weight-bold">
                  <strong>Information</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px'}}/>
                <p className="mb-1 text-white">
                  <Link id="footer-link-thc" to="/cannabis-test">Cannabis Genetic Test</Link>
                </p>
                <p className="mb-1 text-white">
                  <Link id="footer-link-how-it-works" to="how-it-works">How It Works</Link>
                </p>
                <p className="mb-1 text-white">
                  <Link id="footer-link-contact-us" to="/contact">Contact Us</Link>
                </p>
                <p className="mb-1 text-white">
                  <Link id="footer-link-faq" to="faq">FAQ</Link>
                </p>
              </div>


              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="gradient-light text-uppercase font-weight-bold">
                  <strong>Customers</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px', color: 'white'}}/>
                <p className="mb-1 text-white">
                  <Link id="footer-link-register" to="/register">Register Kit</Link>
                </p>
                <p className="mb-1 text-white">
                  <Link id="footer-link-cart" to="/cart">Shopping Cart</Link>
                </p>
                <p className="mb-1 text-white">
                  <Link id="footer-link-kit-instructions" to="/swab-kit-instructions">Swab Kit Instructions</Link>
                </p>
              </div>


              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h6 className="gradient-light text-uppercase font-weight-bold">
                  <strong>Terms</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px'}}/>

                <p className="mb-1 text-white">
                  <Link id="footer-link-privacy" to="/privacy">Privacy Statement</Link>
                </p>
                <p className="mb-1 text-white">
                  <Link id="footer-link-terms-of-service" to="/terms-of-service">Terms Of Service</Link>
                </p>
                <p className="mb-1 text-white">
                  <Link id="footer-link-refund-policy" to="/refund-policy">Refund Policy</Link>
                </p>
              </div>


              <div className="col-md-3 col-lg-3 col-xl-3">
                <h6 className="gradient-light text-uppercase font-weight-bold">
                  <strong>About Us</strong>
                </h6>
                <hr className="mb-2 mt-1 d-inline-block mx-auto" style={{width: '60px'}}/>
                <p className="mb-1 text-white">
                  <Link id="footer-link-company" to="/about">
                    <i className="mb-1"></i>Company</Link>
                </p>
              </div>

            </div>
          </div>

          <div className='d-flex align-items-center justify-content-center'>
            <div className=" dropdown d-flex">
              <a className="nav-link " href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                 aria-expanded="false">
                <div className='pointer nav-item dropdown d-flex align-items-center text-white'>
                  {this.renderUserCountry()}
                  <p className='pl-2 text-white text-nowrap'>{this.props.userCountry} | <span
                    className='change-country text-white'>Change</span></p>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-xl-left" style={{width: "max-content"}}
                   aria-labelledby="navbarDropdown">
                <div className="dropdown-item py-3 pointer" onClick={() => this.modifyUserCountry('Canada')}>
                  <img className='pr-2' src={require('../../assets/images/ca.png')} style={{height: 18}}/> Canada
                </div>
                <div className="dropdown-item py-3 pointer" onClick={() => this.modifyUserCountry('United States')}>
                  <img className='pr-2' src={require('../../assets/images/us.png')} style={{height: 18}}/>United
                  States
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="footer-social text-white w-100">
                <ul className="list-unstyled">
                  <li>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                      <a className="wow" href="https://www.facebook.com/lobogenetics" target="_blank">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                  <li>
                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                      <a className="wow" href="https://twitter.com/lobogenetics" target="_blank">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                  <li>
                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                      <a className="wow" href="https://www.linkedin.com/company/lobo-genetics" target="_blank">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                  <li>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                      <a className="wow" href="https://www.instagram.com/lobogenetics" target="_blank">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                  <li>
                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                      <a className="wow" href="mailto:info@lobogene.com" target="_blank">
                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      </a>
                    </ScrollAnimation>
                  </li>
                </ul>
              </div>
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <p className="company-about text-white w-100">Your use of our services and the information provided
                  through this
                  website, including test results and reports, is for informational purposes only, and is not
                  intended for medical advice or treatment, or for other therapeutic or diagnostic use. Before
                  taking any action based on this information, you must consult an experienced healthcare
                  professional. Your use of our services and this website is governed by the laws of the Province
                  of Ontario and the federal laws of Canada. &copy; {new Date().getFullYear()} Lobo Genetics Inc. All rights
                  reserved.</p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    )
  }

  renderUserCountry() {
    if (this.props.userCountry === 'Canada')
      return (
        <img src={require('../../assets/images/ca.png')} style={{height: 18}}/>
      )

    if (this.props.userCountry === 'United States')
      return (
        <img src={require('../../assets/images/us.png')} style={{height: 18}}/>
      )

    return null;
  }

  modifyUserCountry(country) {
    if (country === this.props.userCountry)
      return;

    this.props.modifyUserCountry(country);
    toast.info('Your country has been updated', {position: 'top-center', autoClose: 2500});
  }
}


const mapStatetoProps = state => ({
  userCountry: state.UserCountry.country
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Footer));

