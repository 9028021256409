import createReducer from "./createReducer";
import * as types from "../actions/types";


export const ThcTestResults = createReducer([], {
  [types.LOGIN_SUCCESS](state, action) {
    let data = action.payload.response.res;
    const customerDetails = action.payload.response.res_cus;
    data = {...data, ...customerDetails}
    if (data.thcTestResults)
      return data.thcTestResults;
    else if (data.cannabisTestResults)
      return data.cannabisTestResults;
    else
      return [];
  },
  [types.REGISTER_THC_SWAB_KIT](state, action) {
    var newState = Object.assign([], state);
    newState.push(action.payload.response)
    return newState;
  },
  [types.REGISTER_CANNABIS_SWAB_KIT](state, action) {
    var newState = Object.assign([], state);
    newState.push(action.payload.response)
    return newState;
  },
  [types.LOGOUT_SUCCESS](state, action) {
    return []
  }
});

export const CbdTestResults = createReducer([], {
  [types.LOGIN_SUCCESS](state, action) {
    let data = action.payload.response.res;
    const customerDetails = action.payload.response.res_cus;
    data = {...data, ...customerDetails}
    if (data.cbdTestResults)
      return data.cbdTestResults;
    else if (data.cannabisTestResults)
      return data.cannabisTestResults;
    else
      return [];
  },
  [types.REGISTER_CBD_SWAB_KIT](state, action) {
    var newState = Object.assign([], state);
    newState.push(action.payload.response)
    return newState;
  },
  [types.REGISTER_CANNABIS_SWAB_KIT](state, action) {
    var newState = Object.assign([], state);
    newState.push(action.payload.response)
    return newState;
  },
  [types.LOGOUT_SUCCESS](state, action) {
    return []
  }
});