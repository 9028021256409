import {bfetch} from "../lib/http";
import {GET_SHOPIFY_CHECKOUT_URL} from "../lib/constants";

export const getCheckoutURL = (quantity) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const cart = getState().Cart.cart;
      const userCountry = getState().UserCountry.country
      const url = GET_SHOPIFY_CHECKOUT_URL + '?thcQuantity=' + cart.thcItemCount + " &cbdQuantity=" + cart.cbdItemCount
        + " &cannabisQuantity=" + cart.cannabisItemCount+ '&country=' + userCountry;
      bfetch(url).then(res => {
        resolve(res)
      }).catch(err =>{
        reject(err)
      })
    })
  }
}
