import React from "react";
import kitthc from "../../assets/images/services-genetic-test-thc.jpeg";
import kitcbd from "../../assets/images/services-genetic-test-cbd.jpeg";
import kitcannabis from "../../assets/images/services-genetic-test-thc.jpeg";
import {Link} from "react-router-dom";
import {isNullOrUndefinedOrEmpty} from "../../lib/helper";

class ExploreKitCTA extends React.PureComponent {

  render() {
    if (this.props.thc) {
      return this.renderTHCKit()
    } else if (this.props.cbd) {
      return this.renderCBDKit()
    }  else if (this.props.cannabis) {
      return this.renderCannabisKit()
    } else {
      return null;
    }
  }

  renderTHCKit() {
    if (isNullOrUndefinedOrEmpty(this.props.thcProduct)) {
      return null;
    }

    const price = this.props.thcProduct.priceRange.maxVariantPrice.amount || 89.00;
    const currency = this.props.thcProduct.priceRange.maxVariantPrice.currencyCode;
    const productTitle = this.props.thcProduct.title;

    return this.renderKit(price, productTitle, kitthc, currency, '/thc-test', 'Discover effects on THC metabolism, mental health and memory.', 'price-item', 'thc');
  }

  renderCBDKit() {
    if (isNullOrUndefinedOrEmpty(this.props.cbdProduct)) {
      return null;
    }
    const price = this.props.cbdProduct.priceRange.maxVariantPrice.amount;
    const currency = this.props.cbdProduct.priceRange.maxVariantPrice.currencyCode;
    const productTitle = this.props.cbdProduct.title;

    return this.renderKit(price, productTitle, kitcbd, currency, '/cbd-test', 'Discover effects for your metabolism of CBD.', 'price-item-cbd', 'cbd');
  }

  renderCannabisKit() {
    if (isNullOrUndefinedOrEmpty(this.props.cannabisProduct)) {
      return null;
    }
    const price = this.props.cannabisProduct.priceRange.maxVariantPrice.amount || 89.00;
    const currency = this.props.cannabisProduct.priceRange.maxVariantPrice.currencyCode || undefined;
    const productTitle = this.props.cannabisProduct.title;

    return this.renderKit(price, productTitle, kitcannabis, currency, '/cannabis-test', 'Discover effects of THC and CBD metabolism, mental health and memory.', 'price-item', 'cannabis');
  }

  renderKit(price, productTitle, pic, currency, redirectUrl, description, clazz, variant) {
    return (
      <div className={"price-item item text-center " + clazz}>
        <div className="price-item-img-top" style={{backgroundImage: `url(${pic})`, height: 275, width: 335, backgroundSize: 'cover'}}
             alt="image" focusable={"false"}>
          <h5 className="alt-font d-inline-block font-weight-normal mb-4 text-white pt-4" style={{fontSize: '26px'}}>{productTitle}</h5>
        </div>
        <div className='p-3 text-black font-weight-normal'>
          <div>
            <p>{description}</p>
          </div>


          <div className="lobo-thin-divider my-4"></div>
          <ul className="d-flex justify-content-between align-items-center list-unstyled text-left mb-4">
            <li><Link className="lobo-link p-0" to={redirectUrl}>See all details <span><i className='fa fa-arrow-right'></i></span> </Link></li>
            <li><h3 className="currency"><span className="sign">{currency === 'CAD'? '' : ''}$</span>{Number.parseFloat(price).toFixed(0)}</h3></li>

          </ul>

          <Link to={'/cannabis-test'} onClick={() => this.props.addProductToCart(1, variant)} className="btn btn-large btn-gradient w-100">Add to cart</Link>
        </div>
      </div>
    )
  }

}

export default ExploreKitCTA
