import * as AuthActions from './auth.js'
import * as SwabKitAction from './swab-kits.js'
import * as CartAction from './cart.js'
import * as ProductAction from './product.js'
import * as AdminAction from './admin.js'
import * as ContactUsAction from "./contact-us.js";
import * as DiscountActions from "./discount.js";

export const ActionCreators = Object.assign({},
	AuthActions,
	SwabKitAction,
	CartAction,
	ProductAction,
	AdminAction,
	ContactUsAction,
	DiscountActions
);
