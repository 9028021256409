import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../../actions'
import CbdTest from "./CbdTest";


const mapStateToProps = state => ({
  product: state.CBDProduct
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const CbdTestContainer = connect(mapStateToProps, mapDispatchToProps)(CbdTest);

export default CbdTestContainer
