import {isNullOrUndefined, isNullOrUndefinedOrEmpty} from "./helper";

export function renderCBDG(props) {
  if (isNullOrUndefinedOrEmpty(props)) {
    return {genotype: '', tag:'', short: '', long: ''}
  }
  let c2 = props.cyp2c192;
  let c3 = props.cyp2c193;
  let c17 = props.cyp2c1917;

  if(c2 === 'CYP2C19_2_PENDING' || c3 === 'CYP2C19_3_PENDING' || c17 === 'CYP2C19_17_PENDING') {
    return {genotype: 'Pending', tag:'inc', short: 'Pending', long: ''}
  }
  if(c2 === 'CYP2C19_2_INCONCLUSIVE' || c3 === 'CYP2C19_3_INCONCLUSIVE' || c17 === 'CYP2C19_17_INCONCLUSIVE') {
    return {genotype: 'Inconclusive', tag:'inc', short: 'Inconclusive', long: ''}
  }

  switch (c2){
    case 'CYP2C19_2_1_1':
      c2 = '*1/*1';
      break;
    case 'CYP2C19_2_2_2':
      c2 = '*2/*2';
      break;
    case 'CYP2C19_2_1_2':
      c2 = '*1/*2';
      break;
  }
  switch (c3){
    case 'CYP2C19_3_1_1':
      c3 = '*1/*1';
      break;
    case 'CYP2C19_3_1_3':
      c3 = '*1/*3';
      break;
    case 'CYP2C19_3_3_3':
      c3 = '*3/*3';
      break;
  }
  switch (c17){
    case 'CYP2C19_17_1_1':
      c17 = '*1/*1';
      break;
    case 'CYP2C19_17_1_17':
      c17 = '*1/*17';
      break;
    case 'CYP2C19_17_17_17':
      c17 = '*17/*17';
      break;
  }

  if (c2 === '*1/*1' && c3 === '*1/*1' && c17 === '*1/*1') {
    return {genotype: '*1/*1', tag:'normal', short: 'Normal Metabolizer', long: 'Your CYP2C19 genotype tells us that you metabolize CBD at a normal pace.'}
  } else if (c2 === '*1/*1' && c3 === '*1/*1' && c17 === '*1/*17') {
    return {genotype: '*1/*17',tag:'fast',  short: 'Fast Metabolizer', long: 'Your CYP2C19 genotype tells us that you metabolize CBD faster than normal.'}
  } else if (c2 === '*1/*1' && c3 === '*1/*1' && c17 === '*17/*17') {
    return {genotype: '*17/*17',tag:'fast',  short: 'Fast Metabolizer', long: 'Your CYP2C19 genotype tells us that you metabolize CBD faster than normal.'}
  } else if (c2 === '*1/*1' && c3 === '*1/*3' && c17 === '*1/*1') {
    return {genotype: '*1/*3',tag:'slow',  short: 'Slow Metabolizer', long: 'Your CYP2C19 genotype tells us that you metabolize CBD  slower than normal.'}
  } else if (c2 === '*1/*1' && c3 === '*1/*3' && c17 === '*1/*17') {
    return {genotype: '*3/*17',tag:'slow',  short: 'Slow Metabolizer', long: 'Your CYP2C19 genotype tells us that you metabolize CBD  slower than normal.'}
  } else if (c2 === '*1/*1' && c3 === '*3/*3' && c17 === '*1/*1') {
    return {
      genotype: '*3/*3',
      tag:'veryslow',
      short: 'Very Slow Metabolizer',
      long: 'Your CYP2C19 genotype tells us that you metabolize CBD much slower than normal.'
    }
  } else if (c2 === '*1/*2' && c3 === '*1/*1' && c17 === '*1/*1') {
    return {genotype: '*1/*2',tag:'slow', short: 'Slow Metabolizer', long: 'Your CYP2C19 genotype tells us that you metabolize CBD  slower than normal.'}
  } else if (c2 === '*1/*2' && c3 === '*1/*1' && c17 === '*1/*17') {
    return {genotype: '*2/*17',tag:'slow', short: 'Slow Metabolizer', long: 'Your CYP2C19 genotype tells us that you metabolize CBD  slower than normal.'}
  } else if (c2 === '*1/*2' && c3 === '*1/*3' && c17 === '*1/*1') {
    return {
      genotype: '*2/*3',
      tag:'veryslow',
      short: 'Very Slow Metabolizer',
      long: 'Your CYP2C19 genotype tells us that you metabolize CBD much slower than normal.'
    }
  } else if (c2 === '*2/*2' && c3 === '*1/*1' && c17 === '*1/*1') {
    return {
      genotype: '*2/*2',
      tag:'veryslow',
      short: 'Very Slow Metabolizer',
      long: 'Your CYP2C19 genotype tells us that you metabolize CBD much slower than normal.'
    }
  } else {
    return {genotype: 'Inconclusive', tag:'inc', short: 'Inconclusive', long: ''}
  }
}

export function getMetabolismString(value) {
  switch (value) {
    case 'CYP2C9_1_1':
    case '*1/*1':
      return {short: 'Normal Metabolizer', long: 'Your CYP2C9 genotype tells us that you metabolize THC normally.'
        , genotype: '*1/*1'};
    case 'CYP2C9_1_3':
    case '*1/*3':
      return {short: 'Slow Metabolizer', long: 'Your CYP2C9 genotype tells us that you metabolize THC more slowly than normal.'
        , genotype: '*1/*3'};
    case 'CYP2C9_3_3':
    case '*3/*3':
      return {short: 'Very Slow Metabolizer', long: 'Your CYP2C9 genotype tells us that you metabolize THC more slowly than normal.'
        , genotype: '*3/*3'};
    case 'CYP2C9_INCONCLUSIVE':
    case 'Inconclusive':
    case 'inconclusive':
      return {short: 'Inconclusive', long: 'Inconclusive', genotype: 'Inconclusive'};
    case 'CYP2C9_PENDING':
    case 'PENDING':
    case 'Pending':
    case 'pending':
      return {short: 'Pending', long: 'Pending', genotype: 'Pending'};
    default:
      return {short: '', long: '', genotype: ''};
  }
}

export function getAkt1String(value) {
  switch ((value || '').replace('AKT1_', '').replace('_','/')) {
    case 'AKT1_T_T':
    case 'T/T':
      return {
        short: 'Normal Risk',
        long: 'You\'re at a normal risk for negative mental health effects from THC. Your AKT1 genotype indicates a normal ' +
          'risk for both short- and long-term effects (anxiety, paranoia, hallucinations) from THC use.'
        , genotype: 'T/T'
      };
    case 'AKT1_C_T':
    case 'C/T':
      return {
        short: 'Intermediate Risk',
        long: 'You\'re at an intermediate risk for negative mental health effects from THC. Your AKT1 genotype indicates ' +
          'that you have an increased risk for short-term adverse effects (anxiety, paranoia, hallucinations) from THC use. ' +
          'Your long term risk level is normal. '
        , genotype: 'C/T'
      };
    case 'AKT1_C_C':
    case 'C/C':
      return {
        short: 'Higher Risk',
        long: 'You\'re at a higher risk for negative mental health effects from THC. Your AKT1 genotype indicates that ' +
          'you are at an increased risk for short- and long-term adverse mental health effects from consuming THC.'
        , genotype: 'C/C'
      };
    case 'INCONCLUSIVE':
    case 'inconclusive':
      return {
        short: 'Inconclusive',
        long: 'Inconclusive'
        , genotype: 'Inconclusive'
      };
    case 'PENDING':
    case 'Pending':
    case 'pending':
      return {short: 'Pending', long: 'Pending', genotype: 'Pending'};
    default:
      return {short: '', long: '', genotype: ''};
  }
}

export function getMemoryString(value) {
  switch (value) {
    case 'COMT_MET_MET':
    case 'Met/Met':
      return {short: 'Normal Risk', long: 'Your COMT genotype indicates that you are at a normal risk for neurocognitive impairments when using THC. '
        , genotype: 'Met/Met'};
    case 'COMT_VAL_MET':
    case 'Val/Met':
      return {
        short: 'Intermediate Risk',
        long: 'Your COMT genotype indicates that you are at an intermediate risk for neurocognitive impairments when using THC.'
        , genotype: 'Val/Met'
      };
    case 'COMT_VAL_VAL':
    case 'Val/Val':
      return {
        short: 'Higher Risk',
        long: 'Your COMT genotype indicates that you are at a higher risk for neurocognitive impairments when using THC'
        , genotype: 'Val/Val'
      };
    case 'COMT_INCONCLUSIVE':
    case 'inconclusive': return { short: 'Inconclusive', long: 'Inconclusive' , genotype: 'Inconclusive'};
    case 'PENDING':
    case 'COMT_PENDING':
    case 'Pending':
    case 'pending': return { short: 'Pending', long: 'Pending' , genotype: 'Pending'};
    default:
      return { short: '', long: '' , genotype: ''};
  }
}

export function userHasGenetics(userProfile) {
  if (isNullOrUndefined(userProfile)) {
    return false;
  }

  if (!isNullOrUndefined(userProfile.akt1) || !isNullOrUndefined(userProfile.comt) || !isNullOrUndefined(userProfile.cyp2c9) ||
    !isNullOrUndefined(userProfile.cyp2c192) || !isNullOrUndefined(userProfile.cyp2c193) || !isNullOrUndefined(userProfile.cyp2c1917)) {
    return true;
  }
}
