import * as types from './types'
import {bfetch} from "../lib/http";
import {REGISTER_SWAB_KIT} from "../lib/constants";
import {isNullOrUndefined, isNullOrUndefinedOrEmpty} from "../lib/helper";
import {REGISTER_CANNABIS_SWAB_KIT} from "./types";

export const registerSwabKit = (swabKitId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(REGISTER_SWAB_KIT, {
        method: 'POST',
        body: swabKitId
      }).then(res => {
        dispatch(doRegisterSwabKitSuccess(res))
        if(!isNullOrUndefined(res.akt1) && !isNullOrUndefined(res.cyp2c192)){
          dispatch(doRegisterCannabisKitSuccess(res))
        }else if(!isNullOrUndefined(res.akt1)){
          dispatch(doRegisterTHCKitSuccess(res))
        }else if(!isNullOrUndefined(res.cyp2c192)){
          dispatch(doRegisterCBDKitSuccess(res))
        }
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const doRegisterSwabKitSuccess = response => {
  return {
    type: types.REGISTER_SWAB_KIT,
    payload: {response},
  }
}

export const doRegisterTHCKitSuccess = response => {
  return {
    type: types.REGISTER_THC_SWAB_KIT,
    payload: {response},
  }
}

export const doRegisterCBDKitSuccess = response => {
  return {
    type: types.REGISTER_CBD_SWAB_KIT,
    payload: {response},
  }
}

export const doRegisterCannabisKitSuccess = response => {
  return {
    type: types.REGISTER_CANNABIS_SWAB_KIT,
    payload: {response},
  }
}
