export const STORAGE_AUTH_TOKEN_KEY = '@LOBOGENE_auth_token';
export const STORAGE_TIP_COUNTER = '@LOBOGENE_tip_counter';

function createApiCall(postUrl) {
  return process.env.REACT_APP_API_REST_ENDPOINT + postUrl;
}

export const FORGOT_PASSWORD_URL =createApiCall('/api/account/reset-password/init');
export const LOGIN_URL = createApiCall('/api/authenticate');
export const ACCOUNT_URL = createApiCall('/api/account');
export const REGISTER_URL = createApiCall('/api/register');
export const CHANGE_PASSWORD = createApiCall('/api/account/change-password');
export const RESET_PASSWORD = createApiCall('/api/account/reset-password/finish');

export const CUSTOMER_DETAIL_URL = createApiCall('/api/customer-details/user');
export const CUSTOMER_DETAIL_PUT_URL = createApiCall('/api/customer-details/user');
export const UPDATE_LOGIN_COUNT = createApiCall('/api/customer-details/update-login');

export const REGISTER_SWAB_KIT = createApiCall('/api/swab-kits/register');


export const GET_SHOPIFY_PRODUCT = createApiCall('/api/shopify-product');
export const GET_SHOPIFY_CHECKOUT_URL = createApiCall('/api/shopify-product/checkout');

export const SUBMIT_CONTACT_US_FORM = createApiCall('/api/contact-us');

// ADMIN
export const ADMIN_FETCH_ALL_SWAB_KITS = createApiCall('/api/swab-kits');
export const ADMIN_CREATE_SWAB_KITS = createApiCall('/api/swab-kits');
export const ADMIN_BULK_UPLOAD_SWAB_KITS = createApiCall('/api/swab-kits/create/many');
export const ADMIN_DELETE_SWAB_KITS = createApiCall('/api/swab-kits');

export const ADMIN_DETACH_SWAB_KITS = createApiCall('/api/admin/test/detach');
export const ADMIN_ATTACH_SWAB_KITS = createApiCall('/api/admin/test/attach');
export const ADMIN_CREATE_TESTS_RESULTS = createApiCall('/api/admin/test/new');

export const ADMIN_FETCH_TESTS_RESULTS = createApiCall('/api/admin/users');
export const ADMIN_UPDATE_TESTS_RESULTS = createApiCall('/api/admin/update');
export const ADMIN_UPDATE_USER_PROFILE = createApiCall('/api/admin/user-details');
export const ADMIN_FETCH_USER_DETAILS = createApiCall('/api/admin/users-details');
export const ADMIN_EMAIL_USER_RESULTS = createApiCall('/api/admin/email-results');