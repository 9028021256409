import React from "react";
import $ from "jquery";

class LoadingScreen extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {


      "use strict";

      /* ===================================
              Loading Timeout
       ====================================== */

      setTimeout(function () {
        $(".loader").fadeOut("slow");
      }, 1500);



  }

  render(){
    return(
      <div className='main-website'>
      <div className="loader">
        <div className="loader-inner">
          <div className="loader-blocks">
            <span className="block-1"></span>
            <span className="block-2"></span>
            <span className="block-3"></span>
            <span className="block-4"></span>
            <span className="block-5"></span>
            <span className="block-6"></span>
            <span className="block-7"></span>
            <span className="block-8"></span>
            <span className="block-9"></span>
            <span className="block-10"></span>
            <span className="block-11"></span>
            <span className="block-12"></span>
            <span className="block-13"></span>
            <span className="block-14"></span>
            <span className="block-15"></span>
            <span className="block-16"></span>
          </div>
        </div>
      </div>
      </div>
    )
  }

}

export default LoadingScreen
