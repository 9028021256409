export function pdfRawHtml() {
  return `
      <div id="print-header" class="print-container print-header">
          <div class="row justify-content-between mt-4">
              <div class="col-7 mt-2">
                  <div><img src="assets/img/lobo-name.png" height="50px">
                      <div class="mt-3">
                          <h3 id="genetic-test" style="color: var(--lobo-text-primary);font-weight: 600;">Cannabis Genetic Test</h3>
                      </div>
                  </div>
              </div>
              <div class="col-5">
                  <div class="row mt-2">
                      <div class="col-5">
                          <h6><strong>Subject ID:</strong></h6>
                          <h6><strong>Report Date:</strong></h6>
                          <h6><strong>Swab ID:</strong>{{{display.swabKitsSpacer}}}</h6>
                          <h6><strong>Location:</strong></h6>
                      </div>
                      <div class="col-7">
                        <h6>{{{display.initials}}}</h6>
                        <h6>{{{display.date}}}</h6>
                        <h6>{{{display.swabKits}}}</h6>
                        <h6>{{{display.location}}}</h6>
                    </div>
                  </div>
              </div>
          </div>
          <hr style="border-width: 1px;border-color: var(--lobo-text-primary);">
      </div>
      <div id="print-footer" class="print-container print-footer">
          <hr style="border-width: 1px;border-color: var(--lobo-text-primary);">
          <div class="row">
              <div class="col-4 text-left">
                  <div class="row">
                      <div class="col-auto align-self-center" style="padding: 0px 0px 10px 10px;"><img src="assets/img/lobo-logo.png" style="height: 35px;"></div>
                      <div class="col">
                          <h6 class="text-uppercase" style="color: var(--lobo-text-primary);">LOBO GENETICS INC.</h6>
                          <h6 style="color: var(--lobo-text-primary);">© 2022. All rights reserved.</h6>
                      </div>
                  </div>
              </div>
              <div class="col-4 text-center">
                  <h6 class="text-uppercase text-center" style="color: var(--lobo-text-primary);">CANNABIS Genetic Test</h6>
                  <h6 class="text-center" style="color: var(--lobo-text-primary);">Report v1.0W</h6>
              </div>
              <div class="col-4 text-right">
                <h6 class="text-uppercase" style="color: var(--lobo-text-primary);">Subject ID:&nbsp;{{{display.initials}}}</h6>
                <h6 style="color: var(--lobo-text-primary);">Location:&nbsp;{{{display.location}}}</h6>
              </div>
          </div>
      </div>
      <div class="table-responsive">
          <table class="table print-report-table">
              <thead>
                  <tr>
                      <th style="border-style: none;padding: 0px;">
                          <div class="page-header-space"></div>
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td style="border-style: none;padding: 0px;">
                          <div id="print-body">
                              <div id="report-summary-page" class="print-container page">
                                  <section class="mt-0 mb-5">
                                      <h3 class="text-uppercase mb-3">Report Summary</h3>
                                      <p>The Lobo Cannabis Genetic Test is a pharmacogenomic test to help identify how an individual's DNA profile can affect their response to THC and CBD. The information contained in this report is based on research related to specific genetic polymorphisms and their influence on&nbsp;pharmacokinetics,&nbsp;pharmacodynamics, and mental health risk factors for cannabis use.</p>
                                  </section>
                                  <section class="mt-1 mb-4">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--cyp2c9-secondary);color: var(--white);padding: 12px;border-radius: 5px;">Pharmacokinetic Genes</h4>
                                      <p class="mb-2">Genes that influence pharmacokenetics can alter drug metabolism resulting in variability in drug blood concentration levels and therapeutic response. Your report includes metabolism genes for THC and CBD.</p>
                                      <div class="table-responsive">
                                          <table class="table gene-table">
                                              <thead>
                                                  <tr>
                                                      <th style="width: 10%;">Gene</th>
                                                      <th style="width: 10%;">Genotype</th>
                                                      <th style="width: 20%;">Result</th>
                                                      <th class="width: 60%;">Description</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>CYP2C9</td>
                                                      <td>{{{display.cyp2c9.genotype}}}</td>
                                                      <td>{{{display.cyp2c9.resultDescriptive}}}</td>
                                                      <td>The CYP2C9 gene is responsible for THC metabolism.</td>
                                                  </tr>
                                                  <tr>
                                                      <td>CYP2C19</td>
                                                      <td>{{{display.cyp2c19.genotype}}}</td>
                                                      <td>{{{display.cyp2c19.resultDescriptive}}}</td>
                                                      <td>The CYP2C19 gene is responsible for CBD metabolism.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                                  <section class="mt-1 mb-4">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--lobo-text-primary);color: var(--white);padding: 12px;border-radius: 5px;">PharmacoDYNAMIC Genes</h4>
                                      <p class="mb-2">Genes that influence pharmacodynamics can alter gene receptors resulting in different responses to drugs such as sensitivity and adverse side effects. Your report includes a genetic marker which can influence the likelihood of neurocognitive impairment when using THC, such as short-term memory loss.</p>
                                      <div class="table-responsive">
                                          <table class="table gene-table">
                                              <thead>
                                                  <tr>
                                                      <th style="width: 10%;">Gene</th>
                                                      <th style="width: 10%;">Genotype</th>
                                                      <th style="width: 20%;">Result</th>
                                                      <th class="width: 60%;">Description</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>COMT</td>
                                                      <td>{{{display.comt.genotype}}}</td>
                                                      <td>{{{display.comt.resultDescriptive}}}</td>
                                                      <td>The COMT&nbsp;gene influences neurocognitive impairment such as short-term memory loss when using THC.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                                  <section class="mt-1 mb-4">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--survey-primary);color: var(--white);padding: 12px;border-radius: 5px;">MENTAL HEALTH&nbsp;RISK Genes</h4>
                                      <p class="mb-2">Genes can influence short- and long-term risk factors with the use of cannabis with THC. Your report includes a genetic risk marker for mental health effects.</p>
                                      <div class="table-responsive">
                                          <table class="table gene-table">
                                              <thead>
                                                  <tr>
                                                      <th style="width: 10%;">Gene</th>
                                                      <th style="width: 10%;">Genotype</th>
                                                      <th style="width: 20%;">Result</th>
                                                      <th class="width: 60%;">Description</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td>AKT1</td>
                                                      <td>{{{display.akt1.genotype}}}</td>
                                                      <td>{{{display.akt1.resultDescriptive}}}</td>
                                                      <td>The AKT1 gene is a mental health risk factor when using THC.</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                      </div>
                                  </section>
                              </div>
                              <div id="pharmacokinetic-page-1" class="print-container page page-header-space">
                                  <section class="mb-5">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--cyp2c9-secondary);color: var(--white);padding: 12px;border-radius: 5px;">CYP2C9 Gene · THC Metabolism</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The way your body metabolizes THC, the intoxicating compound in cannabis, is influenced by the CYP2C9 gene. This gene encodes a liver enzyme that helps metabolize THC in your bloodstream. 15–20% of people carry the CYP2C9*3 genetic variant which causes them to metabolize THC up to 2x to 3x slower than normal.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center mb-3" style="color: var(--lobo-text-primary);">{{{display.cyp2c9.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">‘Slow metabolizers’ should be more cautious when consuming THC, as they will likely experience an increased duration and intensity of intoxication, especially when taking THC in oral form.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">THC&nbsp;Metabolism</h4>
                                              <h4 class="text-center" style="color: var(--lobo-text-primary);">{{{display.cyp2c9.result}}}
                                              {{{display.cyp2c9.icon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>CYP2C9 Genotype</th>
                                                              <th>CYP2C9 Metabolism Status</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>*1/*1</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*1/*3</td>
                                                              <td>Slow</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*3/*3</td>
                                                              <td>Very Slow</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row">
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Slow and Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#006ba6&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;84&quot;,&quot;16&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (84%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #fc3939;"></i>&nbsp;Slow and Very Slow (16%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Slow and Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Africans&quot;,&quot;backgroundColor&quot;:[&quot;#006ba6&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;94&quot;,&quot;6&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Africans&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                      <div class="text-center small mt-2">
                                                          <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (94%)</span></div>
                                                          <div><span><i class="fas fa-circle" style="color: #fc3939;"></i>&nbsp;Slow and Very Slow (6%)</span></div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Slow and Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#006ba6&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;95&quot;,&quot;5&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                      <div class="text-center small mt-2">
                                                          <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (95%)</span></div>
                                                          <div><span><i class="fas fa-circle" style="color: #fc3939;"></i>&nbsp;Slow and Very Slow (5%)</span></div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="pharmacokinetic-page-2" class="print-container page page-header-space">
                                  <section>
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--cyp2c9-secondary);color: var(--white);padding: 12px;border-radius: 5px;">CYP2C19 Gene · CBD Metabolism</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">The way your body metabolizes CBD, the active non-intoxicating compound in cannabis, is influenced by the CYP2C19 gene. This gene encodes a liver enzyme that helps metabolize CBD in your bloodstream. 25% of the population carries the gene variant that causes them to metabolize CBD more slowly than normal. Conversely, 25% of people are fast metabolizers CBD.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center mb-3" style="color: var(--lobo-text-primary);">{{{display.cyp2c19.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">Understanding your rate of CBD metabolism can help you better understand the effects of CBD and how it functions in the body.<br><br>‘Slow metabolizers’ should be aware of the potential for higher concentrations of CBD in the blood stream, and 'fast metabolizers' can experience lower CBD concentrations.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">CBD Metabolism</h4>
                                              <h4 class="text-center" style="color: var(--lobo-text-primary);">{{{display.cyp2c19.result}}}
                                              {{{display.cyp2c19.icon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>CYP2C19&nbsp; Genotype</th>
                                                              <th>CYP2C19 Metabolism Status</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>*1/*17, *17/*17</td>
                                                              <td>Increased</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*1/*1</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*1/*2, *1/*3, *2/*17, *3/*17</td>
                                                              <td>Slow</td>
                                                          </tr>
                                                          <tr>
                                                              <td>*2/*2, *2/*3, *3/*3</td>
                                                              <td>Very Slow</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row">
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Slow&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#4ca8a2&quot;,&quot;#006ba6&quot;,&quot;#f47da7&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;26&quot;,&quot;50&quot;,&quot;20&quot;,&quot;4&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: #4ca8a2;"></i>&nbsp;Increased (26%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (50%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #f47da7"></i>&nbsp;Slow (20%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (4%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Slow&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Africans&quot;,&quot;backgroundColor&quot;:[&quot;#4ca8a2&quot;,&quot;#006ba6&quot;,&quot;#f47da7&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;23&quot;,&quot;44&quot;,&quot;28&quot;,&quot;5&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Africans&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: #4ca8a2;"></i>&nbsp;Increased (23%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (44%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #f47da7"></i>&nbsp;Slow (28%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (5%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Increased&quot;,&quot;Normal&quot;,&quot;Slow&quot;,&quot;Very Slow&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#4ca8a2&quot;,&quot;#006ba6&quot;,&quot;#f47da7&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;7&quot;,&quot;37&quot;,&quot;48&quot;,&quot;8&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: #4ca8a2;"></i>&nbsp;Increased (7%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (37%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #f47da7"></i>&nbsp;Slow (48%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Very Slow (8%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="pharmacodynamic-page" class="print-container page page-header-space">
                                  <section class="mb-5">
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--lobo-text-primary);color: var(--white);padding: 12px;border-radius: 5px;">COMT Gene · MEMORY LOSS</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">Dopamine plays an important role in learning and memory formation. The COMT gene influences how quickly dopamine is cleared from the brain. 30% of people carry the COMT Val/Val genotype, which causes them to clear dopamine more quickly. As a result, these individuals generally have lower dopamine levels.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center" style="color: var(--lobo-text-primary);">{{{display.comt.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">Studies have shown that COMT Val/Val carriers suffer from more neurocognitive impairments when using THC, including up to a 40% reduction in short-term memory.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Memory Loss Risk</h4>
                                              <h4 class="text-center" style="color: var(--lobo-text-primary);">{{{display.comt.result}}}
                                                {{{display.comt.icon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>COMT Genotype</th>
                                                              <th>Memory Loss Risk</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>Met/Met</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>Val/Met</td>
                                                              <td>Intermediate</td>
                                                          </tr>
                                                          <tr>
                                                              <td>Val/Val</td>
                                                              <td>Higher</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row">
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Intermediate&quot;,&quot;Higher&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#006ba6&quot;,&quot;#4e87a0&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;20&quot;,&quot;50&quot;,&quot;30&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (20%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #4E87A0"></i>&nbsp;Intermediate (50%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Higher (30%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Intermediate&quot;,&quot;Higher&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Africans&quot;,&quot;backgroundColor&quot;:[&quot;#006ba6&quot;,&quot;#4E87A0&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;12&quot;,&quot;30&quot;,&quot;58&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Africans&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (12%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #4E87A0"></i>&nbsp;Intermediate (30%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Higher (58%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Intermediate&quot;,&quot;Higher&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;East Asians&quot;,&quot;backgroundColor&quot;:[&quot;#006ba6&quot;,&quot;#4E87A0&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;10&quot;,&quot;40&quot;,&quot;50&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;East Asians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (10%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #4E87A0"></i>&nbsp;Intermediate (40%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Higher (50%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="mental-health-risk-page" class="print-container page page-header-space">
                                  <section>
                                      <h4 class="text-uppercase mb-3" style="text-align: center;background: var(--survey-primary);color: var(--white);padding: 12px;border-radius: 5px;">AKT1 Gene · Mental Health Risk</h4>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>Description</h4>
                                              <p class="mb-0">THC's intoxicating effect or ‘high’ is caused by the release of dopamine in the brain. However, too much dopamine can trigger an increased risk of adverse effects including anxiety, hallucinations and paranoia.<br><br>The AKT1 gene plays an important part in the dopamine signaling pathway in the brain. 25% of people carry the AKT1 C/C genotype that can cause an increased risk for short and long-term THC-induced psychosis effects.</p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Genotype Result</h4>
                                              <h4 class="text-center" style="color: var(--lobo-text-primary);">{{{display.akt1.genotypeDescriptive}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-8">
                                              <h4>What do my results mean?</h4>
                                              <p class="mb-0">While all users of THC have a baseline risk for psychosis, AKT1 C/C carriers should be particularly aware of their elevated risk profile.<br></p>
                                          </div>
                                          <div class="col-4">
                                              <h4 class="text-center">Mental Health Risk</h4>
                                              <h4 class="text-center" style="color: var(--lobo-text-primary);">{{{display.akt1.result}}}
                                              {{{display.akt1.icon}}}</h4>
                                          </div>
                                      </div>
                                      <hr>
                                      <div class="row">
                                          <div class="col-4">
                                              <h4 class="mb-3">How do I compare?</h4>
                                              <div class="table-responsive text-left">
                                                  <table class="table scoring-table">
                                                      <thead>
                                                          <tr>
                                                              <th>AKT1 Genotype</th>
                                                              <th>Mental Health Risk</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>T/T</td>
                                                              <td>Normal</td>
                                                          </tr>
                                                          <tr>
                                                              <td>C/T</td>
                                                              <td>Intermediate</td>
                                                          </tr>
                                                          <tr>
                                                              <td>C/C</td>
                                                              <td>Higher</td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                          </div>
                                          <div class="col-8">
                                              <div class="row justify-content-center">
                                                  <div class="col-4">
                                                      <div>
                                                          <div class="chart-area ml-4"><canvas data-bss-chart="{&quot;type&quot;:&quot;doughnut&quot;,&quot;data&quot;:{&quot;labels&quot;:[&quot;Normal&quot;,&quot;Intermediate&quot;,&quot;Higher&quot;],&quot;datasets&quot;:[{&quot;label&quot;:&quot;Caucasians&quot;,&quot;backgroundColor&quot;:[&quot;#006ba6&quot;,&quot;#4e87a0&quot;,&quot;#fc3939&quot;],&quot;borderColor&quot;:[&quot;#ffffff&quot;,&quot;#ffffff&quot;,&quot;#ffffff&quot;],&quot;data&quot;:[&quot;25&quot;,&quot;50&quot;,&quot;25&quot;]}]},&quot;options&quot;:{&quot;animation&quot;:{&quot;duration&quot;:0},&quot;maintainAspectRatio&quot;:false,&quot;legend&quot;:{&quot;display&quot;:false,&quot;position&quot;:&quot;top&quot;,&quot;reverse&quot;:false,&quot;labels&quot;:{&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}},&quot;title&quot;:{&quot;display&quot;:true,&quot;position&quot;:&quot;top&quot;,&quot;text&quot;:&quot;Caucasians&quot;,&quot;fontColor&quot;:&quot;#282d32&quot;,&quot;fontSize&quot;:14,&quot;fontStyle&quot;:&quot;normal&quot;,&quot;fontFamily&quot;:&quot;'Nunito Sans', sans-serif&quot;}}}"></canvas></div>
                                                          <div class="text-center small mt-2">
                                                              <div><span><i class="fas fa-circle" style="color: #006ba6;"></i>&nbsp;Normal (25%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: #4E87A0"></i>&nbsp;Intermediate (50%)</span></div>
                                                              <div><span><i class="fas fa-circle" style="color: var(--danger);"></i>&nbsp;Higher (25%)</span></div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <hr>
                                  </section>
                              </div>
                              <div id="disclaimer-page" class="print-container page-header-space">
                                  <section class="mb-0">
                                    <h5 class="text-uppercase">Disclaimer</h5>
                                    <p>This report is for informational purposes only and does not and is not intended to convey medical advice and does not constitute the practice of medicine. You should not rely on this information as a substitute for, nor does it replace, professional medical advice, diagnosis, or treatment. Lobo is not responsible for any actions or inaction on a user's part based on the information that is presented in this report.</p>
                                    <p>The use cannabis can come with potential health risks and legal consequences. All individuals should do their own research as well as consult with a healthcare professional prior to considering the use of cannabis to ensure the appropriateness and legality of any cannabis therapy within your jurisdiction.<br></p>
                                  </section>
                              </div>
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  `;
}
