import React from "react";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux'
import {ActionCreators} from "../../actions";
import {withRouter} from "react-router-dom";
import qr from "../../assets/images/lobogene-qr-code.png";
import {isNullOrUndefinedOrEmpty} from "../../lib/helper";
import $ from "jquery";
import {toast} from "react-toastify";
import 'jquery-mask-plugin/dist/jquery.mask.min.js';

class RegisterKit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      kitId: undefined,
      kitIdValidation: ''
    }

    this.registerKit = this.registerKit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    $('#swab-kit-id-js-mask').mask('SS-0000-0000-0000');
  }

  registerKit() {
    if (!isNullOrUndefinedOrEmpty(this.state.kitId))
      this.props.registerSwabKit(this.state.kitId.toUpperCase()).then(res => {
        let isNewCannabisTest = this.state.kitId.toUpperCase().includes('MJ-') ? true : false;
        toast.success('Swab Kit ID registered');
        this.setState({kitId: undefined});
        $('#register-kit-modal').modal('hide');
        let url = '/tutorial';
        url += isNewCannabisTest ? '?test=cannabis' : '';
        this.props.history.push(url);
      }).catch(err => {
        if (err.errorKey === 'kitnotfound') {
          toast.error('Swab Kit ID not found. Please try again or contact support@lobogene.com with your Swab Kit ID')
        } else if (err.errorKey === 'kitinactive') {
          toast.error('Swab Kit ID already registered. Please try again or contact support@lobogene.com with your Swab Kit ID.')
        } else if (err.errorKey === 'badkitformat') {
          toast.error('Swab Kit ID provided is not recognized. Please try again or contact support@lobogene.com with your Swab Kit ID.')
        } else if (err.errorKey === 'useralreadyregisteredthckit') {
          toast.error('You have already registered a THC Genetic Test Swab Kit ID.')
        } else if (err.errorKey === 'useralreadyregisteredcbdkit') {
          toast.error('You have already registered a CBD Genetic Test Swab Kit ID.')
        } else if (err.errorKey === 'useralreadyregisteredcannabiskit') {
          toast.error('You have already registered a Cannabis Genetic Test Swab Kit ID.')
        } else {
          toast.error('Something went wrong. Please try again or contact support at' +
            ' support@lobogene.com with your swab kit ID.')
        }
      })
  }

  handleSubmit(event) {
    event.preventDefault();
    if(/^[A-Za-z]{2}(-[0-9]{4}){3}$/.test(this.state.kitId)) {
      this.setState({kitIdValidation: ''});
      this.registerKit();
    }
    else this.setState({kitIdValidation: 'The Swab Kit ID you have entered is not in the correct format. Please make sure it is in this format: XX-0000-0000-0000 and try again.'});
  }

  render() {

    return (
      <div className=' d-flex justify-content-center'>
        <div className='container p-5 m-5'>
          <div className="text-center"><img src={qr} width={200}/></div>
          <p className="text-center">Enter the 14-digit swab kit ID.</p>
          <form id="register-kit-modal-form" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input className="form-control form-control-lg text-uppercase" type="text" id="swab-kit-id-js-mask"
                     onChange={(e) => {
                       this.setState({kitId: e.target.value, kitIdValidation: ''})
                     }}
                     name="Swab Kit ID" placeholder="Swab Kit ID" required autoComplete="off" minLength={17}
                     maxLength={17}/></div>
              <div className="p-2" style={{color: 'red', fontSize: '12px'}}>{this.state.kitIdValidation}</div>
            <div className="form-group text-center mt-4">
              <button className="btn btn-primary btn-lg border rounded" type="submit">Register</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStatetoProps = state => ({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(RegisterKit));
