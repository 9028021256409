import React from 'react'
import './App.scss';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import ScrollToTop from "./components/ScrollToTop";
import LoadingScreen from "./pages/LoadingScreen/LoadingScreen";
import {store} from "./store/store";
import {Provider} from "react-redux";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Faq from "./pages/Faq/Faq";
import HowItWorks from "./pages/HowItWorks/HowItWorks";
import StartupContainer from "./pages/Startup/StartupContainer";
import Checkout from "./pages/Cart/Checkout/Checkout";
import SwabKitTutorial from "./pages/Dashboard/SwabKitTutorial/SwabKitTutorial";
import MainWebsiteRoute from "./layouts/MainWebsiteRoute";
import MainWesbiteAltRoute from "./layouts/MainWebsiteAltRoute";
import MainWebsiteOtherRoute from "./layouts/MainWebsiteOtherRoute";
import DashboardRoute from "./layouts/DashboardRoute";
import ThcTestContainer from "./pages/OurTests/Thc/ThcTestContainer";
import CartContainer from "./pages/Cart/CartContainer";
import HomeContainer from "./pages/Home/HomeContainer";
import SignInContainer from "./pages/Auth/SignIn/SignInContainer";
import RegisterContainer from "./pages/Auth/Register/RegisterContainer";
import AdminRoute from "./layouts/AdminRoute";
import CbdTestContainer from "./pages/OurTests/Cbd/CbdTestContainer";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Pdf from "./pages/Pdf/Pdf";
import RegisterKit from "./pages/RegisterKit/RegisterKit";
import ResetPasswordContainer from "./pages/Auth/ResetPassword/ResetPasswordContainer";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {isNullOrUndefined, isNullOrUndefinedOrEmpty} from "./lib/helper";
import {saveAffiliateRef, saveDiscount} from "./actions/discount";
import queryString from 'query-string'
import AffiliateProgram from "./pages/AffiliateProgram/AffiliateProgram";
import CannabisTest from "./pages/OurTests/Cannabis/CannabisTest";
import PdfReport from "./pages/Pdf/Download/cannabisPdf";


const SwabKitInstructions = React.lazy(() => import('./pages/SwabKitInstructions/SwabKitInstructions'))
const Privacy = React.lazy(() => import('./pages/Privacy/Privacy'))
const TermsOfService = React.lazy(() => import('./pages/TermsOfService/TermsOfService'))
const TermOfSale = React.lazy(() => import('./pages/TermOfSale/TermOfSale'))
const RefundPolicy = React.lazy(() => import('./pages/RefundPolicy/RefundPolicy'))
const ForgotPasswordContainerLazy = React.lazy(() => import('./pages/Auth/ForgotPassword/ForgotPasswordContainer'))
const DashboardContainer = React.lazy(() => import('./pages/Dashboard/DashboardContainer'))
const AdminHomeContainer = React.lazy(() => import('./pages/Admin/AdminHome/AdminHomeContainer'))

export const APP_STARTED = true;

if (process.env.REACT_APP_ENVIRONMENT === 'production')
  Sentry.init({
    dsn: "https://c62442ff5df14048891e2565052cfd40@o379033.ingest.sentry.io/5792738",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: 'lobogene@' + '1.0.0',
    tracesSampleRate: 1.0,
  })

async function checkTapAffiliate() {
  const redirectParam = window.location.search + '&redirect=true';
  const response = await fetch('https://extreme-ip-lookup.com/json/?key=guToMrdUtXMduuSU1hPr')
    .then(res => res.json())
    .then(response => {
      if (response.country === 'Canada') {
        window.location.href = 'https://shop.lobogene.com' + redirectParam;
        return true;
      } else {
        window.location.href = 'https://shop.us.lobogene.com' + redirectParam;
        return true;
      }
    })
    .catch((data, status) => {
      window.location.href = 'https://shop.us.lobogene.com' + redirectParam;
      return true;
    })
}


function App() {
  if (!isNullOrUndefinedOrEmpty(window.location.search)) {
    const values = queryString.parse(window.location.search);
    if (!isNullOrUndefined(values.ref)) {
      store.dispatch(saveAffiliateRef(values.ref))
      if (isNullOrUndefinedOrEmpty(values.redirect)) {
        checkTapAffiliate();
        return;
      }
    }
    if (!isNullOrUndefined(values.discount)) {
      store.dispatch(saveDiscount(values.discount))
    }
  }
  return (
    <Provider store={store}>
      <ToastContainer/>
      <BrowserRouter>
        <StartupContainer/>
        <LoadingScreen/>
        <ScrollToTop/>
        <div className="App overflow-hidden">
          <Main></Main>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

function Main() {
  return (
    <React.Suspense fallback={
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
      </div>
    }>
      <main>
        <Switch>
          <MainWebsiteRoute exact path='/' component={HomeContainer}/>
          <MainWebsiteRoute exact path='/en_ca' component={HomeContainer}/>
          <MainWebsiteRoute exact path='/en_us' component={HomeContainer}/>
          <MainWesbiteAltRoute exact path='/about' component={AboutUs}/>
          <MainWesbiteAltRoute exact path='/contact' component={ContactUs}/>
          <MainWesbiteAltRoute exact path='/how-it-works' component={HowItWorks}/>
          <MainWebsiteOtherRoute exact path='/test' component={CannabisTest}/>
          <MainWebsiteOtherRoute exact path='/cbd-test' component={CannabisTest}/>
          <MainWebsiteOtherRoute exact path='/thc-test' component={CannabisTest}/>
          <MainWebsiteOtherRoute exact path='/cannabis-test' component={CannabisTest}/>
          <MainWesbiteAltRoute exact path='/faq' component={Faq}/>
          <MainWebsiteOtherRoute exact path='/sign-in' component={SignInContainer}/>
          <MainWebsiteOtherRoute exact path='/forgot-password' component={ForgotPasswordContainerLazy}/>
          <MainWebsiteOtherRoute exact path='/register' component={RegisterContainer}/>
          <MainWebsiteOtherRoute exact path='/account/reset/finish' component={ResetPasswordContainer}/>
          <MainWebsiteOtherRoute exact path='/cart' component={CartContainer}/>
          <MainWebsiteOtherRoute exact path='/checkout' component={Checkout}/>
          <MainWebsiteOtherRoute exact path='/privacy' component={Privacy}/>
          <MainWebsiteOtherRoute exact path='/terms-of-service' component={TermsOfService}/>
          <MainWebsiteOtherRoute exact path='/term-of-sale' component={TermOfSale}/>
          <MainWebsiteOtherRoute exact path='/refund-policy' component={RefundPolicy}/>
          <MainWebsiteOtherRoute exact path='/swab-kit-instructions' component={SwabKitInstructions}/>
          <MainWebsiteOtherRoute exact path='/affiliate-program' component={AffiliateProgram}/>
          <MainWebsiteOtherRoute exact path='/pdf-download' component={PdfReport}/>

          <DashboardRoute exact path='/dashboard/:tab' component={DashboardContainer}/>
          <DashboardRoute exact path='/tutorial' component={SwabKitTutorial}/>
          <DashboardRoute exact path='/pdf' component={Pdf}/>
          <DashboardRoute exact path='/register-kit' component={RegisterKit}/>

          <AdminRoute exact path='/admin' component={AdminHomeContainer}/>

          <Route>
            <Redirect to={'/'}/>
          </Route>
        </Switch>
      </main>
    </React.Suspense>
  );
}

export default App;
