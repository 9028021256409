import React from "react";
import Helmet from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";

class Faq extends React.Component {
  constructor(props) {
    super(props);

  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>FAQ | Lobo Genetics Inc.</title>


          <meta itemProp="name" content="FAQ | Lobo Genetics Inc."/>
          <meta itemProp="description" content="Contact Lobo Genetics Inc."/>
          <meta itemProp="image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="description" content="Contact Lobo Genetics Inc."/>
          <meta property="og:title" content="FAQ | Lobo Genetics Inc."/>
          <meta property="og:description" content="Contact Lobo Genetics Inc."/>
          <meta property="og:url" content="https://www.lobogene.com/faq.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="twitter:title" content="FAQ | Lobo Genetics Inc."/>
          <meta name="twitter:description" content="Contact Lobo Genetics Inc."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        <section className="bg-img-contact mt-0">
          <div className="bg-overlay opacity-9"></div>
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="title text-center">
                  {/*<p className="text-white mb-2">HAVE A QUESTION?</p>*/}
                  <h2 className="text-white">Frequently Asked Questions</h2>

                </div>
              </div>
            </div>
          </div>
        </section>

        <div>
          <section>
            <div className="container">

              <div className="row">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                  <div className="title d-inline-block">
                    <h2 className="gradient-text1 mb-md-3">General</h2>
                  </div>
                </ScrollAnimation>
              </div>

              <section className="accordion pt-2 pb-1" id="accordionReportPages">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse0a" aria-expanded="false" aria-controls="collapse0a">
                        What is Lobo Genetics?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse0a" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Lobo Genetics is a Canadian personalized health-care company that allows for
                        safer cannabis use through DNA testing. We look at the genes that are responsible for
                        interacting with THC and CBD in the human body, and suggest personalized strains of cannabis
                        to try based on your cannabis genetic profile.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse1a" aria-expanded="false" aria-controls="collapse1a">
                        Why should I have my DNA tested?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse1a" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Scientists have identified human genes that influence your reaction to cannabis.
                        Lobo Genetics reveals your cannabis genetic profile, which provides insight on how your
                        body may react to THC and CBD, the main compounds in cannabis.</p>
                      <p>Knowing your variation of these genes will allow you to more safely
                        navigate your cannabis journey. It minimizes the guesswork in dosing and helps
                        you understand your potential risk for adverse effects. Your Cannabis Genetic
                        Profile also includes personalized strain recommendations for cannabis products
                        based on your test results, personal preferences, and desired effect.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse3a" aria-expanded="false" aria-controls="collapse3a">
                        What genes are included in your cannabis genetic test?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse3a" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>
                        <ul className="mb-5">
                          <li>THC Metabolism | CYP2C9 -
                            How sensitive are you to the psychoactive properties in cannabis?
                            How long will it take for THC to no longer be active in your system?
                          </li>
                          <li>
                            CBD Metabolism | CYP2C19 - How sensitive are you to CBD, the cannabinoid in cannabis being
                            used as a go-to for health and wellness? How long will it take for CBD to be active in your
                            system?
                          </li>
                          <li>Mental Health | AKT1 - How likely are you to experience short term mental
                            health effects like anxiety, paranoia, or long-term effects like psychosis or schizophrenia
                            due to THC use?
                          </li>
                          <li>Memory Loss | COMT - How impaired will your memory and other cognitive
                            functions be under the influence of THC?
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse4a" aria-expanded="false" aria-controls="collapse4a">
                        Why did we include these 4 genes in your Cannabis Genetic Profile?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse4a" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>We chose the genes that are considered relevant to cannabis use,
                        and backed by leading experts, literature, and peer-reviewed scientific research.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse5a" aria-expanded="false" aria-controls="collapse5a">
                        How does Lobo Genetics tests differ from other DNA tests?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse5a" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>We only look for 4 genes, which allows the test to be conducted
                        via simple cheek swabs vs messy spit cup, blood, or urine.</p>
                      <p>We do NOT sequence your entire DNA. The limited number of genes
                        tested keeps the cost down to make it affordable. This is why we do not
                        offer a “raw data download” like some other DNA companies.</p><p>Privately owned, Canadian
                      company and lab based out of Toronto, Ontario,
                      means your sample and information stays in Canada.</p><p>Our company is
                      passionate about science! Our teams background consists of genetics, bioscience,
                      pharmacogenetics, laboratory sciences, psychology, and incredible tech developers.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse6a" aria-expanded="false" aria-controls="collapse6a">
                        What technology is used to conduct the Lobo Genetics test?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse6a" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Our genetic testing service uses a molecular technology called Polymerase Chain
                        Reaction (PCR) technology, which is a well-established industry standard method for genotyping
                        and analyzing DNA</p><p>Your sample is handled and processed at our own Canadian testing
                      facility,
                      using our advanced Lobo Cube testing platform, in a controlled environment</p><p>
                      Our team of scientists and experts has done extensive scientific research to develop
                      personalized insights, services and reports</p>
                    </div>
                  </div>
                </div>

              </section>

              <div className="row">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                  <div className="title d-inline-block">
                    <h2 className="gradient-text1 mb-md-3">Ordering and Shipping</h2>
                  </div>
                </ScrollAnimation>
              </div>

              <section className="accordion pt-2 pb-1" id="accordionReportPages">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse0b" aria-expanded="false" aria-controls="collapse0b">
                        What is included in the test kit?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse0b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Each test kit includes: <ul>
                        <li>Hygienic cheek swab kit
                        </li>
                        <li>Welcome Instruction card on how to register
                          your kit and how to collect DNA using the cheek swab kit
                        </li>
                        <li>Envelope with Expedited Prepaid postage to return your swab kit</li>
                      </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse1b" aria-expanded="false" aria-controls="collapse1b">
                        Who can order a Lobo Genetics test kit?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse1b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Our service is available in Canada to individuals at least 19 years old
                        (18 in Alberta). We do not service residents of Quebec at this time.
                        We also ship to individuals in the continental U.S. at least 21 years old
                        (excludes Alaska, Hawaii and the U.S. Protectorates).</p><p>For international orders,
                      please contact us at <a href="mailto:info@lobogene.com\">info@lobogene.com</a>.</p>

                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse2b" aria-expanded="false" aria-controls="collapse2b">
                        How can I purchase the Lobo Genetics cannabis genetic test kit?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse2b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Kits can be purchased at the Lobo Genetics website –
                        <a className='pl-1' href="https://www.lobogene.com">www.lobogene.com</a></p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse3b" aria-expanded="false" aria-controls="collapse3b">
                        What genes are included in your cannabis genetic test?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse3b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p><ul className="mb-5">
                        <li>THC Metabolism | CYP2C9 -
                          How sensitive are you to the psychoactive properties in cannabis?
                          How long will it take for THC to no longer be active in your system?
                        </li>
                        <li>
                          CBD Metabolism | CYP2C19 - How sensitive are you to CBD, the cannabinoid in cannabis being
                          used as a go-to for health and wellness? How long will it take for CBD to be active in your
                          system?
                        </li>
                        <li>Mental Health | AKT1 - How likely are you to experience short term mental
                          health effects like anxiety, paranoia, or long-term effects like psychosis or schizophrenia
                          due to THC use?
                        </li>
                        <li>Memory Loss | COMT - How impaired will your memory and other cognitive
                          functions be under the influence of THC?
                        </li>
                      </ul></p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse4b" aria-expanded="false" aria-controls="collapse4b">
                        Can I purchase a kit as a gift?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse4b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Yes Lobo Genetics’ cannabis genetic test kit makes a great gift to family and friends.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                        What payment options are available?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse5" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Accepted payments include Visa, Mastercard, American Express and PayPal. We do not accept Interac.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse6b" aria-expanded="false" aria-controls="collapse6b">
                        What countries do you ship to?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse6b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Lobo Genetics is actively working with international
                        distributors in various countries for ease of ordering and local availability.
                        The availability will depend on various issues such as import/export laws of the
                        country and transportation ease.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse7b" aria-expanded="false" aria-controls="collapse7b">
                        Can I ship to multiple addresses?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse7b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>At this time, we are unable to support shipping to multiple addresses from one order.
                        In order to ship to multiple addresses, you place as separate individual orders.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse8b" aria-expanded="false" aria-controls="collapse8b">
                        What shipping options are available?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse8b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>In Canada, the default shipping option is Canada Post Standard Mail.
                        Estimated delivery time with Canada Post Standard Mail is typically between 3-8
                        business days within Canada but this can vary depending on your location.
                        We also have an Expedited Delivery options available at additional cost.
                        Estimated delivery time Expedited Parcels are within 2-4 business days,
                        within Canada.</p><p><strong>Note:</strong> For our Expedited Shipping Service,
                      we do not require a signature for delivery. If you are not at home to accept your
                      package, the courier will follow its company practices such as leaving a door-tag
                      or leaving with house-hold members, neighbour, or concierge who will accept mail for your package.
                    </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse9b" aria-expanded="false" aria-controls="collapse9b">
                        How do I track my order?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse9b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>For Canada Post Standard Mail,
                        we unfortunately do not have the ability to track your package.
                        We rely on Canada Post’s standard delivery time, which can take
                        3-8 days to deliver depending on your location.</p><p>For Expedited
                      Delivery Service, we will send you the tracking information with your
                      shipping confirmation. It can take 24 hours for the tracking information
                      to be updated on the carrier website.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse10b" aria-expanded="false" aria-controls="collapse10b">
                        Changing or cancelling an online order.
                      </button>
                    </h5>
                  </div>
                  <div id="collapse10b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>We strongly encourage you to review your order prior to submitting.
                        We will try to accommodate your change or cancel request to best our ability.
                        Please email us with the details at <a href="mailto:support@lobogene.com\">support@lobogene.com
                        </a> before 2PM Eastern Time on the same day as your order placement. Once your order has
                        entered
                        our shipping process, we may not be able to accommodate your request.</p><p>For order changes
                      and
                      cancellation requests beyond our shipping process, please refer to our Refund Policy.
                    </p><p><strong>Note:</strong> Lobo Genetics will use at its discretion to combine shipments
                      intended to same customer and address.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse11b" aria-expanded="false" aria-controls="collapse11b">
                        Returning and refunding an online order.
                      </button>
                    </h5>
                  </div>
                  <div id="collapse11b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Lobo Genetics will accommodate return and refund the purchase price
                        of your unused order less shipping and restock charge. Please contact
                        <a className='pl-1' href="mailto:support@lobogene.com\">support@lobogene.com</a> with your
                        request for a refund within thirty (30) days of placing an order.
                        For Restrictions and Exclusions, please refer to our Refund Policy.
                      </p><p>No refunds will be issued after our laboratory has received
                      and begun processing your sample.</p><p>For kits purchased through
                      an authorized third-party retailer, refunds are subject to third-party refund policy.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse12b" aria-expanded="false" aria-controls="collapse12b">
                        I ordered but haven’t received my kit. Where is my order?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse12b" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>As soon as your order is fulfilled in our order management system,
                        you will first receive an email confirmation that your order is on its way.
                        If you did not receive this confirmation within 2 business days of placing your order,
                        please contact our customer care at <a href="mailto:support@lobogene.com\">support@lobogene.com
                        </a>. You will need to provide your Order Number as reference.</p><p>Lobo Genetics uses
                      Canada Post Standard Mail for its shipments. Depending on the ship to location, average
                      delivery times can take from 2-10 business days.</p><p>For Expedited Shipments, tracking
                      numbers will be provided along aside your email confirmation which can be used to track
                      your package.</p>
                    </div>
                  </div>
                </div>

              </section>


              <div className="row">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                  <div className="title d-inline-block">
                    <h2 className="gradient-text1 mb-md-3">Getting the Test Done</h2>
                  </div>
                </ScrollAnimation>
              </div>
              <section className="accordion pt-2 pb-1" id="accordionReportPages">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse0c" aria-expanded="false" aria-controls="collapse0c">
                        I don’t have an email. Can I still get the test done?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse0c" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>I don’t have an email. Can I still get the test done?"
                        answer: "We use email addresses as the login credentials for your account.
                        You will need access to an email address to register your swab kit and view your results.
                      </p><p>You can always ask someone you trust to use their email address, or for help on how
                      to sign up for a free email service. Sometimes your internet provider offers email for free,
                      and here is the instructions on how to register for a free Gmail account
                      <a href="https://support.google.com/mail/answer/56256?hl=en\">
                        https://support.google.com/mail/answer/56256?hl=en</a>
                    </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse1c" aria-expanded="false" aria-controls="collapse1c">
                        I don’t have a smartphone. Can I view the results on my computer?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse1c" className="collapse" aria-labelledby="headingOne" data-parent="#accordionReportPages">
                    <div className="card-body">
                      <p>Yes! You will receive an email letting you know your results with a link to log into your lobo account to view them from any web browser (smart phone, tablet, or computer).</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse2c" aria-expanded="false" aria-controls="collapse2c">
                        I’m under the influence of cannabis right now, can I still swab myself?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse2c" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Yes. Our test looks for which version of specific genes you have,
                        which has been the same since you were born, and will not change if you get high.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse3c" aria-expanded="false" aria-controls="collapse3c">
                        I have THC / CBD in my system. Will it affect my results?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse3c" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>No. Our test looks for which version of specific genes you have,
                        which has been the same since you were born, and will not change if you
                        take cannabis products.</p><p>It’s a good idea to update the “My Lobo”
                      section of your account preferences with your current frequency of use
                      of these products so that we can give you the most accurate strain suggestions possible.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse4c" aria-expanded="false" aria-controls="collapse4c">
                        I swabbed my cheek more than 24hrs ago? Will this affect the results?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse4c" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>We strongly recommend that you return your kits after swabbing as soon as possible.
                        While we have data suggesting that the cheek DNA swabs are stable for several weeks,
                        there always exists a possibility that DNA can degrade and effect the accuracy of your
                        test results if left over extended time.</p>
                    </div>
                  </div>
                </div>

              </section>


              <div className="row">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                  <div className="title d-inline-block">
                    <h2 className="gradient-text1 mb-md-3">Results</h2>
                  </div>
                </ScrollAnimation>
              </div>
              <section className="accordion pt-2 pb-1" id="accordionReportPages">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse0d" aria-expanded="false" aria-controls="collapse0d">
                        When can I expect to see my results?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse0d" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>We will process your samples within 1-2 business days after the swabs
                        kit has reached our lab in Toronto, Canada. You will receive an email when your results are
                        ready.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse1d" aria-expanded="false" aria-controls="collapse1d">
                        I received an email that my results are ready but I do not see the report on my smartphone.
                      </button>
                    </h5>
                  </div>
                  <div id="collapse1d" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Please check your
                        junk/spam folder.</p><p>Alternatively,
                      you can log on into your account at
                      <a
                         href="https://www.lobogene.com/sign-in">{' https://www.lobogene.com/sign-in. '}</a>
                      On the main page, you will find a My Lobo link, and under this menu,
                      you will be able to view your report.</p>

                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse2d" aria-expanded="false" aria-controls="collapse2d">
                        I mailed the swab kits back over 2 weeks ago but have not received my results/report.
                      </button>
                    </h5>
                  </div>
                  <div id="collapse2d" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Within Canada, we use Canada Post Expedited Parcel to deliver
                        your swab kits to our lab. Shipping times can vary depending on location of origin.
                        If you are worried your sample has been lost in the mail, feel free to reach out to us at:
                        <a href="mailto:support@lobogene.com">{' support@lobogene.com'}</a>.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse3d" aria-expanded="false" aria-controls="collapse3d">
                        How do I create a Lobo Genetics account?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse3d" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Create a free account at the following link:<a href="https://www.lobogene.com/register">
                          {' www.lobogene.com/register'}</a></p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse5d" aria-expanded="false" aria-controls="collapse5d">
                        How do I view my report?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse5d" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>View reports by signing into our platform at the following link
                        - <a className='pl-1' href="https://www.lobogene.com/sign-in">
                          https://www.lobogene.com/sign-in</a></p><p><strong>Note:</strong>
                      If you do not see your report, your test might not have been processed in our facility.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse6d" aria-expanded="false" aria-controls="collapse6d">
                        What do my results tell me?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse6d" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>
                        <ul>
                          <li>THC Metabolism |
                            CYP2C9 - How sensitive are you to the psychoactive
                            properties in cannabis? How long will it take for
                            THC to no longer be active in your system?
                          </li>
                          <li>CBD Metabolism |
                            CYP2C19 - How sensitive are you to CBD, the cannabinoid in cannabis
                            being used as a go-to for health and wellness? How long will it take
                            for CBD to be active in your system?
                          </li>
                          <li>Mental Health
                            | AKT1 - How likely are you to experience short term mental health
                            effects like anxiety, paranoia, or long-term effects like psychosis
                            or schizophrenia due to THC use?
                          </li>
                          <li>Memory Loss |
                            COMT - How impaired will your memory and other cognitive
                            functions be under the influence of THC?
                          </li>
                        </ul>
                      </p>
                      <p>Knowing your variation of these
                        genes will allow you to more safely navigate your cannabis journey, and minimizes the
                        guesswork in dosing and the likelihood of adverse effects.</p><p>Then you can access your
                      personalized strain selections for cannabis products chosen specifically for your unique
                      cannabis genetic profile.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse7e" aria-expanded="false" aria-controls="collapse7e">
                        I received my test report and I see inconclusive results.
                        Why did this happen? What happens now?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse7e" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Most of the time an inconclusive result occurs when
                        there is not enough of your cheek DNA sample on a swab.
                        Please refer to our video instructions on the swabbing
                        technique prior to swabbing.</p><p><strong>Tip:</strong>&nbsp;Swallow
                        a few times to reduce the amount of saliva in your
                        mouth before you attempt to swab</p><p>We will contact you
                        with instructions on what to do next, typically we send you
                        a new swab kit and are happy to walk you through how to swab correctly.</p><p>
                        If you haven't heard from us, check your junk mail for email instructions and feel free
                        to reach out to us at: <a href="mailto:support@lobogene.com\">support@lobogene.com</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse8e" aria-expanded="false" aria-controls="collapse8e">
                        How can I access my personalized strain suggestions?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse8e" className="collapse" aria-labelledby="headingOne" data-parent="#accordionReportPages">
                    <div className="card-body">
                      <p>Log into your lobo account at:&nbsp;<a href="https://www.lobogene.com/sign-in">www.lobogene.com/sign-in</a></p>
                      <p>Click on My Lobo</p>
                      <p>Scroll down to select your desired effect and view your product recommendations.</p>
                      <p>If you’ve taken a Cannabis Genetic Test with us, once your results have been uploaded to your account from our lab, and you’ve completed your preferences, your suggestions will be personalized to you.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse9e" aria-expanded="false" aria-controls="collapse9e">
                        I am trying to use cannabis to help with a specific issue.
                        Will the results give me suggestions based on a medical condition?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse9e" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Your reports will give you the information needed to understand how your
                        genetics will play a role in how your body will react to THC and CBD, but it’s best
                        to share your results with your health care professionals so they can determine how to
                        adjust your course of treatment.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse10e" aria-expanded="false" aria-controls="collapse10e">
                        How can I share my results with friends or medical professionals?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse10e" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>
                        <ol>
                          <li>Log into your lobo account at:&nbsp;<a href="https://www.lobogene.com/sign-in">www.lobogene.com/sign-in</a></li>
                          <li>Click “My Lobo” from the menu at the top</li>
                          <li>Click “Tests” from the menu at the top</li>
                          <li>Click “View Test Results” on the test you want to share to open it up</li>
                          <li>Click “Share Your Results” from the top of the page</li>
                          <li>Highlight the “copy link” address from the box, right click on it and selectcopy</li>
                          <li>Paste the link into an email or text message to send it to whomever you like.</li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>

              </section>


              <div className="row">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                  <div className="title d-inline-block">
                    <h2 className="gradient-text1 mb-md-3">Privacy</h2>
                  </div>
                </ScrollAnimation>
              </div>
              <section className="accordion pt-2 pb-1" id="accordionReportPages">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse0f" aria-expanded="false" aria-controls="collapse0f">
                        What happens to my DNA after the test is completed?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse0f" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Your DNA samples are anonymized on receipt at our lab and discarded after processing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse1f" aria-expanded="false" aria-controls="collapse1f">
                        How do I know my personal information is safe & secure?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse1f" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Lobo Genetics understands and respects your privacy.
                        Please see our Privacy Statement at:
                        <a className='pl-1' href="https://www.lobogene.com/privacy">https://www.lobogene.com/privacy</a>
                      </p>

                    </div>
                  </div>
                </div>

              </section>


              <div className="row">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} className="col-md-12 text-center wow ">
                  <div className="title d-inline-block">
                    <h2 className="gradient-text1 mb-md-3">Cannabis Recommendations</h2>
                  </div>
                </ScrollAnimation>
              </div>
              <section className="accordion pt-2 pb-1" id="accordionReportPages">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse0g" aria-expanded="false" aria-controls="collapse0g">
                        How do you determine which strains are right for me?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse0g" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>We take a look at your full Cannabis Genetic Profile, preferences,
                        and frequency of use into consideration to create a personalized, proprietary
                        algorithm that chooses strains that may work well for you to use cannabis in a safe way.
                      </p><p><strong>Example:</strong> someone who has an increased risk for mental health effects
                      from THC use will notice all of their strain selections are lower in THC and contain some CBD
                      (based on clinical research that CBD can reduce the risk of these adverse effects).
                    </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse1g" aria-expanded="false" aria-controls="collapse1g">
                        How do you determine which strains are located in each of the desired effect categories?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse1g" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>We have developed our proprietary algorithm for strain suggestions based
                        on relevant research, scientific experiments and machine learning techniques.
                        We have identified the effects of each cannabis product (recreationally available in Canada)
                        based on its genotype and chemotype. These include the unique characteristics of each product
                        (cultivar) including the strain family / lineage, potency of cannabinoids including THC & CBD,
                        terpene profile etc.</p><p>We also have tested the validated the results with the help of a
                      number of cannabis connoisseurs, budtenders and average cannabis users.</p><p><strong>Example:
                    </strong> In the sleep category we are looking for Indica Afghanica dominant strains that
                      are high in THC and the terpene myrcene because we know (through science & research) that
                      the broad leaf cannabis plants with these characteristics typically have a sleepy effect.
                      If a budtender lets us know that this particular strain for some reason doesn’t make most
                      people feel that way, we will remove it from that category.</p>

                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse2g" aria-expanded="false" aria-controls="collapse2g">
                        I had a bad trip, will the recommendations help me?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse2g" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>While we aren’t able to tell you for certain why you
                        had a bad trip in the past, when you take the Lobo THC test,
                        we may flag the underlying cause for people prone to bad trips
                        (slow metabolism and/or higher risk for mental health affects).
                      </p><p>Your results are considered when making personalized strain
                      suggestions, and will show you products that are lower in THC potency
                      (one factor in bad trips), and you can view products higher in CBD,
                      which is known to reduce the incidence of adverse effects from THC.</p>
                    </div>
                  </div>
                </div>


                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse5g" aria-expanded="false" aria-controls="collapse5g">
                        What does the thumbs up or thumbs down feature do to my personalized recommendations?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse5g" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>This allows you to record which strains worked
                        well for you (thumbs up), or maybe ones you didn’t like
                        as much (thumbs down). As you document your cannabis
                        journey we are able to adjust your algorithm to give
                        you more strain suggestions that are similar to the things you like.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse6g" aria-expanded="false" aria-controls="collapse6g">
                        What is the watch list feature for in my personalized recommendations?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse6g" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Watchlist products are highlighted in your
                        personalized strain recommendations and kept at the
                        bottom of the main page of “My Lobo”</p><p>This allows
                      you to keep a close eye on when your favourite products
                      are in stock at local retailers, and soon you will be able to reserve them for pick up.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse7g" aria-expanded="false" aria-controls="collapse7g">
                        Why are terpenes highlighted in the desired effects category?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse7g" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Some terpenes have been linked to work synergistically
                        with THC and CBD, the main compounds in cannabis,
                        to achieve specific desired effects.</p><p>
                      The research has its limitations,
                      but the evidence is strong enough to correlate specific terpenes with specific effects.
                      With cannabis being legal now in Canada, more research is underway to strengthen the connection
                      between terpenes and their effects.</p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                              data-target="#collapse8g" aria-expanded="false" aria-controls="collapse8g">
                        I’m taking some medications, will your recommendations let me know about possible
                        interactions?
                      </button>
                    </h5>
                  </div>
                  <div id="collapse8g" className="collapse" aria-labelledby="headingOne"
                       data-parent="#accordionReportPages">

                    <div className="card-body">
                      <p>Our recommendation engine does not consider your personal medical history
                        in the algorithm but knowing your Cannabis Genetic Test results can give your health
                        care professionals come excellent insight.</p><p>You should share your results with
                      your health care professionals so they can look for possible interactions between cannabis
                      and your medications, and to keep an eye on how your symptoms change when using cannabis products
                      .</p><p>There is an easy way to share your results with your health care professionals,
                      outlined in question #22 of this FAQ page.</p>
                    </div>
                  </div>
                </div>

              </section>

            </div>
          </section>


        </div>

      </>
    )
  }

}

export default Faq
