import React from "react";
import {isNullOrUndefined, isNullOrUndefinedOrEmpty} from "../../../lib/helper";
import $ from 'jquery'
import {toast} from "react-toastify";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      fname: undefined,
      lname: undefined,
      email: undefined,
      month: undefined,
      day: undefined,
      year: undefined,
      gender: undefined,
      country: undefined,
      region: undefined,
      promotionalEmail: false,
      enableRecommendation: false,
      frequency: undefined
    }
    const user = this.props.user.userProfile;
    if (!isNullOrUndefinedOrEmpty(user)) {
      this.state.fname = user.fname;
      this.state.lname = user.lname;
      this.state.email = user.email;
      this.state.month = Number.parseInt(user.dob.split('-')[0]);
      this.state.day = Number.parseInt(user.dob.split('-')[1]);
      this.state.year = Number.parseInt(user.dob.split('-')[2]);
      this.state.gender = user.gender;
      this.state.country = user.country;
      this.state.region = user.region;
      this.state.promotionalEmail = user.promotionalEmail;
      this.state.enableRecommendation = user.enableRecommendation;
      if (user.customerSurvey) {
        this.state.frequency = user.customerSurvey.frequency;
      }
    }

    this.submitCustomerDetails = this.submitCustomerDetails.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (JSON.stringify(this.props.user.userProfile) !== JSON.stringify(nextProps.user.userProfile)) {
      const user = nextProps.user.userProfile;
      this.state.fname = user.fname;
      this.state.lname = user.lname;
      this.state.email = user.email;
      this.state.month = Number.parseInt(user.dob.split('-')[0]);
      this.state.day = Number.parseInt(user.dob.split('-')[1]);
      this.state.year = Number.parseInt(user.dob.split('-')[2]);
      this.state.gender = user.gender;
      this.state.country = user.country;
      this.state.region = user.region;
      this.state.promotionalEmail = user.promotionalEmail;
      this.state.enableRecommendation = user.enableRecommendation;
      if (user.customerSurvey) {
        this.state.frequency = user.customerSurvey.frequency;
      }
    }

    return true
  }

  submitCustomerDetails(e) {
    e.preventDefault();
    this.setState({submitted: true})
    const dob = this.state.month + '-' + this.state.day + '-' + this.state.year;
    let payload = {
      id: this.props.user.userProfile.id,
      fname: this.state.fname,
      lname: this.state.lname,
      dob: dob,
      country: this.state.country,
      region: this.state.region,
      gender: this.state.gender,
      promotionalEmail: this.state.promotionalEmail,
      enableRecommendation: this.state.enableRecommendation,
      user: {id: this.props.user.userProfile.id},
    }

    if (!isNullOrUndefinedOrEmpty(this.state.frequency)) {
      payload = {...payload, ...{customerSurvey: {frequency: this.state.frequency}}}
    }

    this.props.submitCustomerDetails(payload).then(res => {
      this.setState({submitted: false})
      toast.success('Your profile has been updated.')
      $('#profile-settings-modal').modal('hide')
    }).catch(err => {
      this.setState({submitted: false})
      toast.error('Something went wrong, please try again!')
    })
  }

  render() {
    return (
      <div>
        <div className="modal fade " role="dialog" tabIndex={-1} id="profile-settings-modal">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title text-primary" style={{color: 'var(--survey-primary)'}}>Profile Settings</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <form id="profile-settings-modal-form" onSubmit={this.submitCustomerDetails}>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group"><label>First Name</label>
                        <input className="form-control" type="text" required
                               pattern="[A-Za-z '-]{2,}" defaultValue={this.state.fname}
                               onChange={(e) => this.setState({fname: e.target.value})}
                               name="firstNameForm"/>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input className="form-control" type="text" required
                               pattern="[A-Za-z '-]{2,}" defaultValue={this.state.lname}
                               onChange={(e) => this.setState({lname: e.target.value})}
                               name="lastNameForm"/>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <div className="d-flex justify-content-between">
                          <label className="mr-2">Email</label>
                          <a href="#"
                             style={{
                               fontSize: '12px',
                               textDecoration: 'underline'
                             }}
                             data-toggle="modal"
                             data-target="#change-password-modal"
                             data-dismiss="modal">change
                            password</a></div>
                        <input className="form-control" type="email" required defaultValue={this.state.email}
                               name="emailForm"
                               placeholder="email address" disabled/>
                      </div>
                    </div>
                  </div>
                  <label className="mt-2">Age</label>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group"><select value={this.state.month} className="form-control" required
                                                          name="monthForm"
                                                          onChange={(e) => this.setState({month: e.target.value})}>
                        <option value="">Month</option>
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                      </select></div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <select value={this.state.day} className="form-control" required
                          name="dayForm"
                          onChange={(e) => this.setState({day: e.target.value})}>
                        <option value="">Day</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                        <option value={16}>16</option>
                        <option value={17}>17</option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20</option>
                        <option value={21}>21</option>
                        <option value={22}>22</option>
                        <option value={23}>23</option>
                        <option value={24}>24</option>
                        <option value={25}>25</option>
                        <option value={26}>26</option>
                        <option value={27}>27</option>
                        <option value={28}>28</option>
                        <option value={29}>29</option>
                        <option value={30}>30</option>
                        <option value={31}>31</option>
                      </select></div>
                    </div>
                    <div className="col">
                      <div className="form-group"><select value={this.state.year} className="form-control" required
                                                          name="yearForm"
                                                          onChange={(e) => this.setState({year: e.target.value})}>
                        <option value="">Year</option>
                        <option value={2003}>2003</option>
                        <option value={2002}>2002</option>
                        <option value={2001}>2001</option>
                        <option value={2000}>2000</option>
                        <option value={1999}>1999</option>
                        <option value={1998}>1998</option>
                        <option value={1997}>1997</option>
                        <option value={1996}>1996</option>
                        <option value={1995}>1995</option>
                        <option value={1994}>1994</option>
                        <option value={1993}>1993</option>
                        <option value={1992}>1992</option>
                        <option value={1991}>1991</option>
                        <option value={1990}>1990</option>
                        <option value={1989}>1989</option>
                        <option value={1988}>1988</option>
                        <option value={1987}>1987</option>
                        <option value={1986}>1986</option>
                        <option value={1985}>1985</option>
                        <option value={1984}>1984</option>
                        <option value={1983}>1983</option>
                        <option value={1982}>1982</option>
                        <option value={1981}>1981</option>
                        <option value={1980}>1980</option>
                        <option value={1979}>1979</option>
                        <option value={1978}>1978</option>
                        <option value={1976}>1976</option>
                        <option value={1975}>1975</option>
                        <option value={1974}>1974</option>
                        <option value={1973}>1973</option>
                        <option value={1972}>1972</option>
                        <option value={1971}>1971</option>
                        <option value={1970}>1970</option>
                        <option value={1969}>1969</option>
                        <option value={1968}>1968</option>
                        <option value={1967}>1967</option>
                        <option value={1966}>1966</option>
                        <option value={1965}>1965</option>
                        <option value={1964}>1964</option>
                        <option value={1963}>1963</option>
                        <option value={1962}>1962</option>
                        <option value={1961}>1961</option>
                        <option value={1960}>1960</option>
                        <option value={1959}>1959</option>
                        <option value={1958}>1958</option>
                        <option value={1957}>1957</option>
                        <option value={1956}>1956</option>
                        <option value={1955}>1955</option>
                        <option value={1954}>1954</option>
                        <option value={1953}>1953</option>
                        <option value={1952}>1952</option>
                        <option value={1951}>1951</option>
                        <option value={1950}>1950</option>
                        <option value={1949}>1949</option>
                        <option value={1948}>1948</option>
                        <option value={1947}>1947</option>
                        <option value={1946}>1946</option>
                        <option value={1945}>1945</option>
                        <option value={1944}>1944</option>
                        <option value={1943}>1943</option>
                        <option value={1942}>1942</option>
                        <option value={1941}>1941</option>
                        <option value={1940}>1940</option>
                        <option value={1939}>1939</option>
                        <option value={1938}>1938</option>
                        <option value={1937}>1937</option>
                        <option value={1936}>1936</option>
                        <option value={1935}>1935</option>
                        <option value={1934}>1934</option>
                        <option value/>
                      </select></div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-4">
                      <div className="form-group"><label>Gender</label>
                        <select value={this.state.gender} className="form-control" name="genderForm"
                                onChange={(e) => this.setState({gender: e.target.value})}>
                          <option value="OTHER">N/A</option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                        </select></div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group">
                        <label>Country</label>
                        <select value={this.state.country} className="form-control" name="countryForm"
                                onChange={(e) => this.setState({country: e.target.value})}>
                          <option value="Canada">Canada</option>
                          <option value="United States">United States</option>
                        </select></div>
                    </div>
                    {this.showRegionDropDown('Canada') ?
                      <div className="col">
                        <div className="form-group">
                          <label>Province</label>
                          <select value={this.state.region} onChange={(e) => this.setState({region: e.target.value})}
                                  className="form-control" required name="provinceForm" data-ca="Province">
                            <option value="">Province</option>
                            <option value="Ontario" data-country="Canada">Ontario
                            </option>
                            <option value="Alberta" data-country="Canada">Alberta
                            </option>
                            <option value="British columbia" data-country="Canada">British
                              Columbia
                            </option>
                            <option value="Saskatchewan" data-country="Canada">
                              Saskatchewan
                            </option>
                            <option value="Manitoba" data-country="Canada">Manitoba
                            </option>
                            <option value="Quebec" data-country="Canada">Quebec
                            </option>
                            <option value="Newfoundland And Labrador" data-country="Canada">
                              Newfoundland and Labrador
                            </option>
                            <option value="New Brunswick" data-country="Canada">New
                              Brunswick
                            </option>
                            <option value="NovaScotia" data-country="Canada">Nova
                              Scotia
                            </option>
                            <option value="Price EdwardIsland" data-country="Canada">Prince
                              Edward Island
                            </option>
                          </select></div>
                      </div> : null}

                    {this.showRegionDropDown('United States') ?
                      <div className="col">
                        <div className="form-group">
                          <label>State</label>
                          <select value={this.state.region} onChange={(e) => this.setState({region: e.target.value})}
                                  data-blank="No regions" data-ca="Province" data-us="State"
                                  className="form-control w-100" required name="stateForm">
                            <option value="">State</option>
                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Delaware">Delaware</option>
                            <option value="District Of Columbia">District Of Columbia</option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="New York">New York</option>
                            <option value="North Carolina">North Carolina</option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennsylvania">Pennsylvania</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">South Carolina</option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                          </select>
                        </div>
                      </div> : null}
                  </div>
                  <hr></hr>
                  <div className="form-row">
                    <div className="col">
                      <div>
                        <h3 className="text-primary">Preferences</h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="form-row">
                      <div className="col">
                        <div>
                          <div className="form-group"><label>Frequency of Cannabis Usage</label>
                            <select className="form-control" name="frequencyForm" defaultValue={this.state.frequency}
                                    onChange={(e) => this.setState({frequency: e.target.value})}>
                              <option value="">--</option>
                              <option value="DAILY">Daily</option>
                              <option value="WEEKLY">Weekly</option>
                              <option value="MONTHLY">Monthly</option>
                              <option value="RARELY">Infrequent</option>
                              <option value="NEVER_USED">Never Used</option>
                            </select></div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox" id="formCheck-recommendation-opt-in"
                               checked={this.state.enableRecommendation}
                               onChange={(e) => this.setState({enableRecommendation: !this.state.enableRecommendation})}
                               name="recommendation-opt-in"/>
                        <label className="custom-control-label" htmlFor="formCheck-recommendation-opt-in">
                          Show Cannabis Product Recommendations and offers
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox"
                               id="formCheck-marketing-opt-in" checked={this.state.promotionalEmail}
                               onChange={(e) => this.setState({promotionalEmail: !this.state.promotionalEmail})}
                               name="marketing-opt-in"/><label className="custom-control-label"
                                                               htmlFor="formCheck-marketing-opt-in">Exclusive
                        promotions and updates</label></div>
                    </div>
                  </div>
                  <div className="form-group text-center mt-4">
                    {!this.state.submitted ?
                      <button className="btn btn-primary btn-lg border rounded" type='submit'>Update Settings
                      </button> :
                      <button className="btn btn-primary btn-lg border rounded" type='button'>Saving...
                      </button>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  showRegionDropDown(type) {
    if (this.state.country === 'ca' || this.state.country === 'Canada') {
      if (type !== 'Canada') {
        return false
      }
    } else if (this.state.country === 'us' || this.state.country === 'United States') {
      if (type !== 'United States') {
        return false
      }
    }

    return true;
  }
}

export default Profile
