import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {ActionCreators} from '../../actions/index'
import ExploreKitCTA from "./ExploreKitCTA";
import {CannabisProduct} from "../../reducers/product";


const mapStateToProps = state => ({
  thcProduct: state.THCProduct,
  cbdProduct: state.CBDProduct,
  cannabisProduct: state.CannabisProduct,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

const ExploreKitCTAContainer = connect(mapStateToProps, mapDispatchToProps)(ExploreKitCTA);

export default ExploreKitCTAContainer
