import React from "react";
import $ from "jquery";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      oldPassword: undefined,
      newPassword: undefined,
      confirmPassword: undefined
    }
    this.submitPasswordChange = this.submitPasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  submitPasswordChange() {
    if (this.state.newPassword !== this.state.confirmPassword) {
      alert('Your password and confirm password does not match')
      return;
    }

    this.setState({submitted: true})
    const payload = {currentPassword: this.state.oldPassword, newPassword: this.state.newPassword}

    this.props.submitChangePassword(payload).then(res => {
      this.setState({submitted: false, oldPassword: undefined, newPassword: undefined, confirmPassword: undefined})
      alert('Your password has been changed.')
      $('#change-password-modal').modal('hide')
    }).catch(err => {
      this.setState({submitted: false})
      if (err.title === 'Incorrect password') {
        alert('Your current password is not correct')
      } else {
        alert('Something went wrong, please try again!')
      }

    })

  }

  handleSubmit(event) {
    event.preventDefault();
    this.submitPasswordChange();
    return;
  }

  render() {
    return (
      <div>
        <div className="modal fade" role="dialog" tabIndex={-1} id="change-password-modal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title text-primary" style={{color: 'var(--survey-primary)'}}>Change Password</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <form id="change-password-modal-form" onSubmit={this.handleSubmit}>
                  <div className="form-row">
                    <div className="col">
                      <div className="form-group mb-5"><label className="mr-2">Current Password</label>
                        <input className="form-control" type="password"
                               required="required"
                               onChange={(e) => {
                                 this.setState({oldPassword: e.target.value})
                               }}
                               name="currentPasswordForm"
                               autoComplete="off"/>
                      </div>
                      <div className="form-group"><label className="mr-2">New Password</label>
                        <input className="form-control" type="password"
                               required minLength="6"
                               onChange={(e) => {
                                 this.setState({newPassword: e.target.value})
                               }}
                               name="newPasswordForm" autoComplete="off"/>
                      </div>
                      <div className="form-group"><label className="mr-2">Confirm Password</label>
                        <input className="form-control" type="password"
                               required minLength="6"
                               onChange={(e) => {
                                 this.setState({confirmPassword: e.target.value})
                               }}
                               name="confirmPasswordForm"
                               autoComplete="off"/></div>
                    </div>
                  </div>
                  <div className="form-group text-center mt-4">
                    <button className="btn btn-primary btn-lg border rounded" type="submit">Change Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangePassword
