import createReducer from './createReducer'
import * as types from '../actions/types'
import {localStorageGet, localStorageRemove, localStorageSet} from "../lib/localStorage-helper";
import {isNullOrUndefined} from "../lib/helper";


const cart = {
  cart: {
    thcItemCount: 0,
    cbdItemCount: 0,
    cannabisItemCount: 0
  }
}

function fetchCart() {

  //TODO temp, remove below line after next release
  localStorageRemove('LOBOGENE_public-cart');
  const cartResult = localStorageGet('LOBOGENE_public-cart')
  if (isNullOrUndefined(cartResult)) {
    return cart
  } else {
    return JSON.parse(cartResult);
  }
}

export const Cart = createReducer(fetchCart(), {
  [types.ADD_TO_CART](state, action) {
    var newState = Object.assign({}, state);
    const quantity = action.payload.quantity;
    const variant = action.payload.variant;

    if (variant === 'thc') {
      newState.cart.thcItemCount = newState.cart.thcItemCount + quantity
      if (newState.cart.thcItemCount < 0) { //min number of test someone can order
        newState.cart.thcItemCount = 0
      }
      if (newState.cart.thcItemCount > 25) { //max number of test someone can order
        newState.cart.thcItemCount = 25
      }
    } else if (variant === 'cbd') {
      newState.cart.cbdItemCount = newState.cart.cbdItemCount + quantity
      if (newState.cart.cbdItemCount < 0) { //min number of test someone can order
        newState.cart.cbdItemCount = 0
      }
      if (newState.cart.cbdItemCount > 25) { //max number of test someone can order
        newState.cart.cbdItemCount = 25
      }
    } else if (variant === 'cannabis') {
      newState.cart.cannabisItemCount = newState.cart.cannabisItemCount + quantity
      if (newState.cart.cannabisItemCount < 0) { //min number of test someone can order
        newState.cart.cannabisItemCount = 0
      }
      if (newState.cart.cannabisItemCount > 25) { //max number of test someone can order
        newState.cart.cannabisItemCount = 25
      }
    }
    localStorageSet('LOBOGENE_public-cart', JSON.stringify(newState));
    return newState;
  },
  [types.EMPTY_CART](state, action) {
    var newState = Object.assign({}, state);
    newState = {
      cart: {
        thcItemCount: 0,
        cbdItemCount: 0,
        cannabisItemCount: 0
      }
    };
    localStorageRemove('LOBOGENE_public-cart');
    return newState;
  }
})
