import {store} from '../store/store';
import jwtDecode from 'jwt-decode';
import {doLogoutSuccess, navigateToLoginScreen} from "../actions/auth";

let defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

let userTokens = null;

store.subscribe(() => {
  userTokens = store.getState().UserAccessToken;
  if(userTokens && Object.keys(userTokens).length) {
    defaultHeaders['Authorization'] = userTokens ? ('Bearer ' + userTokens) : '';
  }
});


const handleErrors = response => {
  if (response.ok && (response.url.includes('change-password') || response.url.includes('reset-password')
    || response.url.includes('/api/register'))) {
    return {}
  }
  if (response.ok) {
    if (response.status === 204)
      return {}
    return response.json();
  }
  const responseJson = response.json();
  if (responseJson) {
    return responseJson.then(json => {
      throw json;
    });
  }
  throw response;
}

export const bfetch = async (url, options) => {
  if (!options) {
    options = {};
  }

  try {
    if (userTokens) {
      const decodedJwt = jwtDecode(userTokens.jwt);
      if (decodedJwt.exp * 1000 < (new Date()).getTime()) {
        store.dispatch(doLogoutSuccess({}));
        store.dispatch(navigateToLoginScreen())
        return null;
      }
    }
  } catch (e) {

  }

  if (!options.method) {
    options.method = options.method || 'GET';
  }

  options.headers = Object.assign({}, defaultHeaders, options.headers);

  if (typeof (options.body) !== 'string') {
    options.body = JSON.stringify(options.body);
  }
  return fetch(url, options)
    .then(handleErrors);
}
