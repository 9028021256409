import * as types from './types'

export function addProductToCart(quantity, variant) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch(addToCart(quantity, variant))
    });
  }
}

export const addToCart = (quantity, variant) => {
  return {
    type: types.ADD_TO_CART,
    payload: {quantity, variant},
  }
}

export function emptyCart() {
  return (dispatch, getState) => {
    dispatch(emptyAllCart());

  }
}

export function emptyAllCart() {
  return {
    type: types.EMPTY_CART,
  }
}
