import React from "react";
import {Link} from "react-router-dom";
import {isNullOrUndefined} from "../../../lib/helper";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      emailConfirm: undefined,
      password: undefined,
      passwordConfirm: undefined,
      country: undefined,
      region: undefined,
      fname: undefined,
      lname: undefined,
      month: undefined,
      day: undefined,
      year: undefined,
      sex: undefined,

      loading: false,
      errorMessage: undefined
    }

    this.state.country = this.props.userCountry;

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  registerUser() {
    this.setState({loading: true, errorMessage: undefined})
    if (this.state.email !== this.state.emailConfirm) {
      this.setState({loading: false, errorMessage: 'Email and Confirm Email do not match'})
      return;
    }
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({loading: false, errorMessage: 'Password and Confirm Password do not match'})
      return;
    }
    const dob = this.state.month + '-' + this.state.day + '-' + this.state.year;

    this.props.registerUser(this.state.email, this.state.password, this.state.fname, this.state.lname, this.state.country, this.state.region, dob, this.state.sex, true).then(res => {
      this.setState({loading: false, errorMessage: undefined})
      this.props.history.push('/dashboard/overview')
    }).catch(err => {
      console.error(err)
      this.setState({loading: false, errorMessage: err.title})
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.registerUser();
    return;
  }

  render() {
    return (
      <>
        <section id="registerkit" className="auth register">
          <div className="container">


            <div className="row pt-5 pt-md-3">
              <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 mx-auto my-4">
                <h2 className="alt-color text-center mb-5">Create your account</h2>

                <form className="text-left" onSubmit={this.handleSubmit}>

                  <div className="form-label-group mb-3">
                    <input placeholder="First name" autoComplete="email" className="form-control" required="required"
                           aria-label="Confirm First Name" type="text" name="user[fname]" id="user_fname"
                           value={this.state.fname} onChange={(e) => this.setState({fname: e.target.value})}/>
                    <div className="invalid-feedback">A valid email is required</div>
                  </div>
                  <div className="form-label-group mb-3">
                    <input placeholder="Last name" autoComplete="email" className="form-control" required="required"
                           aria-label="Confirm Last Name" type="text" name="user[lname]" id="user_lname"
                           value={this.state.lname} onChange={(e) => this.setState({lname: e.target.value})}/>
                    <div className="invalid-feedback">A valid email is required</div>
                  </div>
                  <div className="form-label-group lobo-form-col2">
                    <div className='pb-2'>Country</div>
                    <div id="countryselect">
                      <div className='row'>
                        <div className='col-6'>
                          <select id="country" className="form-control w-100" name="country_code"
                                  value={this.state.country} onChange={(e) => this.setState({country: e.target.value})}>
                            <option value="Canada" selected="">Canada</option>
                            <option value="United States">United States</option>
                          </select>
                        </div>
                        {this.showRegionDropDown('Canada') ?
                        <div className='col-6'>
                          <select id="region" className="form-control w-100"
                                  name="user[user_profile_attributes][region_id]"
                                  data-blank="No regions" data-ca="Province" data-us="State" required="required"
                                  value={this.state.region} onChange={(e) => this.setState({region: e.target.value})}>

                            <option value="">Province</option>
                            <option value="Ontario" data-country="Canada">Ontario</option>
                            <option value="Alberta" data-country="Canada">Alberta</option>
                            <option value="British Columbia" data-country="Canada">British Columbia</option>
                            <option value="Saskatchewan" data-country="Canada">Saskatchewan</option>
                            <option value="Manitoba" data-country="Canada">Manitoba</option>
                            <option value="Quebec" data-country="Canada">Quebec</option>
                            <option value="Newfoundland And Labrador" data-country="Canada">Newfoundland and Labrador
                            </option>
                            <option value="New Brunswick" data-country="Canada">New Brunswick</option>
                            <option value="NovaScotia" data-country="Canada">Nova Scotia</option>
                            <option value="Price Edward Island" data-country="Canada">Prince Edward Island</option>
                          </select>
                        </div>: null}
                        {this.showRegionDropDown('United States') ?
                          <div className="col-6">
                            <div className="form-group">
                              <select value={this.state.region} onChange={(e) => this.setState({region: e.target.value})}
                                      data-blank="No regions" data-ca="Province" data-us="State"
                                      className="form-control w-100" required name="stateForm">
                                <option value="">State</option>
                                <option value="Alabama">Alabama</option>
                                <option value="Alaska">Alaska</option>
                                <option value="Arizona">Arizona</option>
                                <option value="Arkansas">Arkansas</option>
                                <option value="California">California</option>
                                <option value="Colorado">Colorado</option>
                                <option value="Connecticut">Connecticut</option>
                                <option value="Delaware">Delaware</option>
                                <option value="District Of Columbia">District Of Columbia</option>
                                <option value="Florida">Florida</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Idaho">Idaho</option>
                                <option value="Illinois">Illinois</option>
                                <option value="Indiana">Indiana</option>
                                <option value="Iowa">Iowa</option>
                                <option value="Kansas">Kansas</option>
                                <option value="Kentucky">Kentucky</option>
                                <option value="Louisiana">Louisiana</option>
                                <option value="Maine">Maine</option>
                                <option value="Maryland">Maryland</option>
                                <option value="Massachusetts">Massachusetts</option>
                                <option value="Michigan">Michigan</option>
                                <option value="Minnesota">Minnesota</option>
                                <option value="Mississippi">Mississippi</option>
                                <option value="Missouri">Missouri</option>
                                <option value="Montana">Montana</option>
                                <option value="Nebraska">Nebraska</option>
                                <option value="Nevada">Nevada</option>
                                <option value="New Hampshire">New Hampshire</option>
                                <option value="New Jersey">New Jersey</option>
                                <option value="New Mexico">New Mexico</option>
                                <option value="New York">New York</option>
                                <option value="North Carolina">North Carolina</option>
                                <option value="North Dakota">North Dakota</option>
                                <option value="Ohio">Ohio</option>
                                <option value="Oklahoma">Oklahoma</option>
                                <option value="Oregon">Oregon</option>
                                <option value="Pennsylvania">Pennsylvania</option>
                                <option value="Rhode Island">Rhode Island</option>
                                <option value="South Carolina">South Carolina</option>
                                <option value="South Dakota">South Dakota</option>
                                <option value="Tennessee">Tennessee</option>
                                <option value="Texas">Texas</option>
                                <option value="Utah">Utah</option>
                                <option value="Vermont">Vermont</option>
                                <option value="Virginia">Virginia</option>
                                <option value="Washington">Washington</option>
                                <option value="West Virginia">West Virginia</option>
                                <option value="Wisconsin">Wisconsin</option>
                                <option value="Wyoming">Wyoming</option>
                              </select>
                            </div>
                          </div> : null}
                      </div>
                    </div>

                  </div>

                  <div className="form-label-group lobo-form-date my-3">
                    <div className='pb-2'>Enter your date of birth to
                      confirm you are of legal age
                    </div>
                    <div>
                      <div className='row'>
                        <div className='col-4'>
                          <select id="user_user_profile_attributes_date_of_birth_2i"
                                  value={this.state.month} onChange={(e) => this.setState({month: e.target.value})}
                                  name="user[user_profile_attributes][date_of_birth(2i)]" className="form-control w-100"

                                  required="required">
                            <option value="">Month</option>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                        <div className='col-4'>
                          <select id="user_user_profile_attributes_date_of_birth_3i"
                                  value={this.state.day} onChange={(e) => this.setState({day: e.target.value})}
                                  name="user[user_profile_attributes][date_of_birth(3i)]" className="form-control w-100"
                                  required="required">
                            <option value="">Day</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </select>
                        </div>
                        <div className='col-4'>
                          <select id="user_user_profile_attributes_date_of_birth_1i"
                                  value={this.state.year} onChange={(e) => this.setState({year: e.target.value})}
                                  name="user[user_profile_attributes][date_of_birth(1i)]" className="form-control w-100"
                                  required="required">
                            <option value="">Year</option>
                            <option value="2003">2003</option>
                            <option value="2002">2002</option>
                            <option value="2001">2001</option>
                            <option value="2000">2000</option>
                            <option value="1999">1999</option>
                            <option value="1998">1998</option>
                            <option value="1997">1997</option>
                            <option value="1996">1996</option>
                            <option value="1995">1995</option>
                            <option value="1994">1994</option>
                            <option value="1993">1993</option>
                            <option value="1992">1992</option>
                            <option value="1991">1991</option>
                            <option value="1990">1990</option>
                            <option value="1989">1989</option>
                            <option value="1988">1988</option>
                            <option value="1987">1987</option>
                            <option value="1986">1986</option>
                            <option value="1985">1985</option>
                            <option value="1984">1984</option>
                            <option value="1983">1983</option>
                            <option value="1982">1982</option>
                            <option value="1981">1981</option>
                            <option value="1980">1980</option>
                            <option value="1979">1979</option>
                            <option value="1978">1978</option>
                            <option value="1977">1977</option>
                            <option value="1976">1976</option>
                            <option value="1975">1975</option>
                            <option value="1974">1974</option>
                            <option value="1973">1973</option>
                            <option value="1972">1972</option>
                            <option value="1971">1971</option>
                            <option value="1970">1970</option>
                            <option value="1969">1969</option>
                            <option value="1968">1968</option>
                            <option value="1967">1967</option>
                            <option value="1966">1966</option>
                            <option value="1965">1965</option>
                            <option value="1964">1964</option>
                            <option value="1963">1963</option>
                            <option value="1962">1962</option>
                            <option value="1961">1961</option>
                            <option value="1960">1960</option>
                            <option value="1959">1959</option>
                            <option value="1958">1958</option>
                            <option value="1957">1957</option>
                            <option value="1956">1956</option>
                            <option value="1955">1955</option>
                            <option value="1954">1954</option>
                            <option value="1953">1953</option>
                            <option value="1952">1952</option>
                            <option value="1951">1951</option>
                            <option value="1950">1950</option>
                            <option value="1949">1949</option>
                            <option value="1948">1948</option>
                            <option value="1947">1947</option>
                            <option value="1946">1946</option>
                            <option value="1945">1945</option>
                            <option value="1944">1944</option>
                            <option value="1943">1943</option>
                            <option value="1942">1942</option>
                            <option value="1941">1941</option>
                            <option value="1940">1940</option>
                            <option value="1939">1939</option>
                            <option value="1938">1938</option>
                            <option value="1937">1937</option>
                            <option value="1936">1936</option>
                            <option value="1935">1935</option>
                            <option value="1934">1934</option>
                            <option value="1933">1933</option>
                            <option value="1932">1932</option>
                            <option value="1931">1931</option>
                            <option value="1930">1930</option>
                            <option value="1929">1929</option>
                            <option value="1928">1928</option>
                            <option value="1927">1927</option>
                            <option value="1926">1926</option>
                            <option value="1925">1925</option>
                            <option value="1924">1924</option>
                            <option value="1923">1923</option>
                            <option value="1922">1922</option>
                            <option value="1921">1921</option>
                            <option value="1920">1920</option>
                            <option value="1919">1919</option>
                            <option value="1918">1918</option>
                            <option value="1917">1917</option>
                            <option value="1916">1916</option>
                            <option value="1915">1915</option>
                            <option value="1914">1914</option>
                            <option value="1913">1913</option>
                            <option value="1912">1912</option>
                            <option value="1911">1911</option>
                            <option value="1910">1910</option>
                            <option value="1909">1909</option>
                            <option value="1908">1908</option>
                            <option value="1907">1907</option>
                            <option value="1906">1906</option>
                            <option value="1905">1905</option>
                            <option value="1904">1904</option>
                            <option value="1903">1903</option>
                            <option value="1902">1902</option>
                            <option value="1901">1901</option>
                            <option value="1900">1900</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="invalid-feedback">You must be over 19.</div>
                  </div>

                  <div className="form-label-group lobo-form-col2 my-3">
                    <div className='pb-2'>
                      <OverlayTrigger
                        key={'top'}
                        placement={'top'}
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            We use this to show you gender-specific reports, health information, and research insights.
                          </Tooltip>
                        }
                      >
                        <div>Biological Sex
                          <i className="fa fa-info-circle ml-1 alt-color" aria-hidden="true"></i>
                        </div>

                      </OverlayTrigger>
                    </div>

                    <div id="sexselect">
                      <div className='row'>
                        <div className='col-12'>
                          <select id="sex" className="form-control w-100" name="sex" required="required"
                                  value={this.state.sex} onChange={(e) => this.setState({sex: e.target.value})}>
                            <option value="">Please specify</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="OTHER">N/A</option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className="form-label-group mb-3">
                    <input placeholder="Email" autoComplete="email" className="form-control" required="required"
                           value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}
                           aria-label="Email" type="email" name="user[email]" id="user_email"/>
                    <div className="invalid-feedback">A valid email is required</div>
                  </div>

                  <div className="form-label-group mb-3">
                    <input placeholder="Confirm Email" autoComplete="email" className="form-control" required="required"
                           value={this.state.emailConfirm}
                           onChange={(e) => this.setState({emailConfirm: e.target.value})}
                           aria-label="Confirm Email" type="email" name="user[confirm_email]" id="user_confirm_email"/>
                    <div className="invalid-feedback">A valid email is required</div>
                  </div>

                  <div className="form-label-group mb-3">
                    <input placeholder="Password" autoComplete="new password" className="form-control"
                           value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}
                           required="required" aria-label="Password" type="password" name="user[password]" minLength={6}
                           maxLength={99}
                           id="user_password"/>
                    <div className="invalid-feedback">A valid password is required</div>
                  </div>

                  <div className="form-label-group mb-3">
                    <input placeholder="Confirm password" autoComplete="password" className="form-control"
                           value={this.state.passwordConfirm}
                           onChange={(e) => this.setState({passwordConfirm: e.target.value})}
                           required="required" aria-label="Confirm password" type="password" minLength={6}
                           maxLength={99}
                           name="user[password_confirmation]" id="user_password_confirmation"/>
                    <div className="invalid-feedback">The passwords need to match</div>
                  </div>

                  {!isNullOrUndefined(this.state.errorMessage) ?
                    <div class='text-danger'>
                      {this.state.errorMessage}
                    </div>
                    : null}

                  <p className="text-center mt-3">By continuing you agree to the <Link
                    to="/terms-of-service" className='text-primary'>Terms of Service</Link> and <Link
                    to="/privacy" className='text-primary'>Privacy Statement</Link>. You can adjust
                    communication preferences at any time.</p>


                  <fieldset name="fsaction" id="fsaction">
                    <div className="actions text-center">
                      {!this.state.loading ?
                        <input type="submit" name="commit" value="Create account"
                               className="btn btn-large btn-gradient mt-3" data-disable-with="Create account"/>
                        :
                        <div className="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>}
                    </div>
                  </fieldset>

                </form>
                <p className="text-center mt-3">Already have an account? <Link
                  to="/sign-in" className='text-primary'>Sign in</Link></p>
              </div>

            </div>
          </div>
        </section>
      </>
    )
  }

  showRegionDropDown(type) {
    if (this.state.country === 'Canada') {
      if (type !== 'Canada') {
        return false
      }
    } else if (this.state.country === 'United States') {
      if (type !== 'United States') {
        return false
      }
    }

    return true;
  }


}

export default Register
