import React from "react";
import {isNullOrUndefinedOrEmpty} from "../../../lib/helper";

class GeneticsReportSummary extends React.Component {

  render() {
    let result1 = {resultSensitivity: undefined, resultSSRI: undefined};
    let result2 = null;
    let result3 = null;
    let result4 = null;
    let result5 = null;
    if (!isNullOrUndefinedOrEmpty(this.props.testResults)) {
      switch (this.props.testResults[0].htr2a) {
        case ('HTR2A_C_C'):
          result1 = {resultSensitivity: 'Reduced', resultSSRI: 'Increased'}
          break;
        case ('HTR2A_C_T'):
          result1 = {resultSensitivity: 'Normal', resultSSRI: 'Normal'}
          break;
        case ('HTR2A_T_T'):
          result1 = {resultSensitivity: 'Increased', resultSSRI: 'Normal'}
          break;
        case ('HTR2A_INCONCLUSIVE'):
          result1 = {resultSensitivity: 'Inconclusive', resultSSRI: 'Inconclusive'}
          break;
        case ('HTR2A_PENDING'):
          result1 = {resultSensitivity: 'NO RESULT', resultSSRI: 'NO RESULT'}
          break;
      }
      switch (this.props.testResults[0].cyp2b6) {
        case ('CYP2B6_1_1'):
          result2 = 'Normal'
          break;
        case ('CYP2B6_1_6'):
          result2 = 'Slow'
          break;
        case ('CYP2B6_6_6'):
          result2 = 'Very Slow'
          break;
        case ('CYP2B6_INCONCLUSIVE'):
          result2 = 'Inconclusive'
          break;
        case ('CYP2B6_PENDING'):
          result2 = 'NO RESULT'
          break;
      }
      switch (this.props.testResults[0].c4a) {
        case ('C4A_ZERO'):
          result3 = 'Normal'
          break;
        case ('C4A_ONE'):
          result3 = 'Normal'
          break;
        case ('C4A_TWO'):
          result3 = 'Normal'
          break;
        case ('C4A_THREE'):
          result3 = 'Normal'
          break;
        case ('C4A_FOUR'):
          result3 = 'Increased'
          break;
        case ('C4A_FIVE'):
          result3 = 'Increased'
          break;
        case ('C4A_INCONCLUSIVE'):
          result3 = 'Inconclusive'
          break;
        case ('C4A_PENDING'):
          result3 = 'NO RESULT'
          break;
      }

      switch (this.props.testResults[0].nrg1) {
        case ('NRG1_C_C'):
          result4 = 'Normal'
          break;
        case ('NRG1_C_T'):
          result4 = 'Normal'
          break;
        case ('NRG1_T_T'):
          result4 = 'Increased'
          break;
        case ('NRG1_INCONCLUSIVE'):
          result4 = 'Inconclusive'
          break;
        case ('NRG1_PENDING'):
          result4 = 'NO RESULT'
          break;
      }
      switch (this.props.testResults[0].disc1) {
        case ('DISC1_A_A'):
          result5 = 'Normal'
          break;
        case ('DISC1_A_T'):
          result5 = 'Normal'
          break;
        case ('DISC1_T_T'):
          result5 = 'Increased'
          break;
        case ('DISC1_INCONCLUSIVE'):
          result5 = 'Inconclusive'
          break;
        case ('DISC1_PENDING'):
          result5 = 'NO RESULT'
          break;
      }
    } else {
      result1 = {resultSensitivity: 'NO RESULT', resultSSRI: 'NO RESULT'}
      result2 = 'NO RESULT'
      result3 = 'NO RESULT'
      result4 = 'NO RESULT'
      result5 = 'NO RESULT'
    }
    return (
      <div>
        <div className="modal fade" role="dialog" tabIndex={-1} id="summary-genetics-modal1">
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" style={{color: 'var(--genetics-primary)'}}>Summary Genetics Report</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <div className="row no-gutters" id="sensitivity-genetics-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3" src={require("../../../assets/images/img-dna-helix-transp.png")}
                         width="20%"
                         style={{background: 'var(--genetics-primary)', padding: '10px'}}/>
                    <div>
                      <h4>HTR2A Serotonin Gene</h4>
                      <p>Classical psychedelics response to drugs such as psilocybin, LSD and DMT. SSRI adverse drug reaction response to drugs such
                        as Paxil.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div id="sensitivity-genetics-summary-results">
                      <div className="text-center mb-3">
                        <h4 className="mb-2">Psychedelics Sensitivity</h4>
                        <h4 className={this.returnResultColor(result1.resultSensitivity)} style={{color: this.returnResultColor(result1.resultSensitivity)}}>
                          {result1.resultSensitivity}
                          {result1.resultSensitivity === 'Increased' ? <i className="pl-1 fa fa-arrow-circle-o-up"/> : null}
                          {result1.resultSensitivity === 'Reduced' ? <i className="pl-1 fa fa-arrow-circle-o-down"/> : null}
                        </h4>

                      </div>
                      <div className="text-center">
                        <h4 className="mb-2">SSRI Sensitivity</h4>
                        <h4 className={this.returnResultColor(result1.resultSensitivity)} style={{color: this.returnResultColor(result1.resultSensitivity)}}>
                          {result1.resultSSRI}
                          {result1.resultSSRI === 'Increased' ? <i className="pl-1 fa fa-arrow-circle-o-up"/> : null}
                          {result1.resultSSRI === 'Reduced' ? <i className="pl-1 fa fa-arrow-circle-o-down"/> : null}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row no-gutters" id="metabolism-genetics-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3" src={require("../../../assets/images/img-dna-helix-transp.png")}
                         width="20%"
                         style={{background: 'var(--genetics-secondary)', padding: '10px'}}/>
                    <div>
                      <h4>CYP2B6 Metabolism Gene</h4>
                      <p>Slower ketamine metabolism can increase duration, intensity of effect and adverse drug reactions.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div id="metabolism-genetics-summary-results">
                      <div className="text-center">
                        <h4 className="mb-2">Ketamine Metabolism</h4>
                        <h4 className={this.returnResultColor(result2)} style={{color: this.returnResultColor(result2)}}>
                          {result2}
                          {result2 === 'Slow' ? <i className="pl-1 fa fa-arrow-circle-o-down"/> : null}
                          {result2 === 'Very Slow' ? <i className="pl-1 fa fa-arrow-circle-o-down"/> : null}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row no-gutters" id="risk-c4a-genetics-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3" src={require("../../../assets/images/img-dna-helix-transp.png")}
                         width="20%"
                         style={{background: 'var(--genetics-tertiary)', padding: '10px'}}/>
                    <div>
                      <h4>C4A Risk Gene CNV</h4>
                      <p>High C4A copy number variation can increase mental health risk.</p>
                    </div>
                  </div>
                  <div className="col" id="risk-c4a-genetics-summary-results">
                    <div className="text-center">
                      <h4 className="mb-2">&nbsp;Mental Health Risk</h4>
                      <h4 className={this.returnResultColor(result3)} style={{color: this.returnResultColor(result3)}}>
                        {result3}
                        {result3 === 'Increased' ? <i className="pl-1 fa fa-arrow-circle-o-up"/> : null}
                      </h4>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row no-gutters" id="risk-nrg1-genetics-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3" src={require("../../../assets/images/img-dna-helix-transp.png")}
                         width="20%"
                         style={{background: 'var(--genetics-tertiary)', padding: '10px'}}/>
                    <div>
                      <h4>NRG1 Risk Gene</h4>
                      <p>Can reduce synaptic plasticity and disrupt neuron connections which can increase mental health risk.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div id="risk-nrg1-genetics-summary-results">
                      <div className="text-center">
                        <h4 className="mb-2">&nbsp;Mental Health Risk</h4>
                        <h4 className={this.returnResultColor(result4)} style={{color: this.returnResultColor(result4)}}>
                          {result4}
                          {result4 === 'Increased' ? <i className="pl-1 fa fa-arrow-circle-o-up"/> : null}
                          {result4 === 'Reduced' ? <i className="pl-1 fa fa-arrow-circle-o-down"/> : null}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div className="row no-gutters" id="risk-disc1-genetics-summary">
                  <div className="col-md-12 col-lg-6 d-flex flex-row mb-2">
                    <img className="mb-auto mr-3" src={require("../../../assets/images/img-dna-helix-transp.png")}
                         width="20%"
                         style={{background: 'var(--genetics-tertiary)', padding: '10px'}}/>
                    <div>
                      <h4>DISC1 Risk Gene</h4>
                      <p>Well-established risk factor amongst different populations and ethnicities for psychiatric disorders.</p>
                    </div>
                  </div>
                  <div className="col">
                    <div id="risk-nrg1-genetics-summary-results-1">
                      <div className="text-center">
                        <h4 className="mb-2">&nbsp;Mental Health Risk</h4>
                        <h4 className={this.returnResultColor(result5)} style={{color: this.returnResultColor(result5)}}>
                          {result5}
                          {result5 === 'Increased' ? <i className="pl-1 fa fa-arrow-circle-o-up"/> : null}
                          {result5 === 'Reduced' ? <i className="pl-1 fa fa-arrow-circle-o-down"/> : null}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-primary btn-lg border rounded" type="button" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  returnResultColor(resultSensitivity) {
    switch (resultSensitivity) {
      case 'NO RESULT':
        return 'text-secondary'
        break;
    }
    return 'var(--genetics-primary)'
  }
}

export default GeneticsReportSummary;
