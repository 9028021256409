import createReducer from "./createReducer";
import * as types from "../actions/types";


const thcDefault = {
  "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIwNjk5MzMyNjA4OTY=",
  "availableForSale": true,
  "descriptionHtml": "<p class=\"card-text\">Your THC genetic profile with personalized reports and actionable insights, delivered directly to your smartphone.</p>\n<ul class=\"card-text\">\n<li>Metabolism: Understand your sensitivity to THC</li>\n<li>Mental Health: Explore your short and long-term risk factors associated with THC use</li>\n<li>Memory: Discover how THC can affect your memory</li>\n<li>Product recommendations based on your unique genetic results</li>\n<li>Future updates: Latest research and studies, recommendations and exclusive offers</li>\n</ul>",
  "title": "THC Genetic Test",
  "priceRange": {
    "maxVariantPrice": {
      "amount": "70.0",
      "currencyCode": "CAD"
    }
  },
  "images": {
    "edges": [
      {
        "node": {
          "src": "https://cdn.shopify.com/s/files/1/0085/7525/4624/products/LOBOmonogram_RGB_1.jpg?v=1555341636"
        }
      }
    ]
  }
}

export const THCProduct = createReducer(thcDefault, {
  [types.FETCH_SHOPIFY_PRODUCT_SUCCESS](state, action) {
    var newState = Object.assign({}, state);
    action.data.data.products.edges.forEach(product => {
      if (product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIwNjk5MzMyNjA4OTY=' ||
          product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQxNjI2ODI1MTk2NDA=') {
        newState = product.node;
      }
    })
    return newState;
  },
  [types.FETCH_SHOPIFY_PRODUCT_FAILURE](state, action) {
    var newState = Object.assign({}, state);
    newState = thcDefault;
    return newState;
  },
});

const cbdDefault = {
  "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIyNDY2ODczNTkwNzI=",
  "availableForSale": true,
  "descriptionHtml": "<p class=\"card-text\">Your CBD genetic profile with personalized report and actionable insights, delivered directly to your smartphone.</p>\n<ul class=\"card-text\">\n<li>Metabolism: Understand how quickly your body processes CBD, and how different CBD formats will affect you differently.</li>\n<li>Product recommendations based on your unique genetic profile</li>\n<li>Future updates: Latest research and studies, recommendations and exclusive offers</li>\n</ul>",
  "title": "CBD Genetic Test",
  "priceRange": {
    "maxVariantPrice": {
      "amount": "60.0",
      "currencyCode": "CAD"
    }
  },
  "images": {
    "edges": [
      {
        "node": {
          "src": "https://cdn.shopify.com/s/files/1/0085/7525/4624/products/LOBOmonogram_RGB_1_3d1380b0-4496-461e-b79a-f11adaa3e1fb.jpg?v=1564604203"
        }
      }
    ]
  }
}

export const CBDProduct = createReducer(cbdDefault, {
  [types.FETCH_SHOPIFY_PRODUCT_SUCCESS](state, action) {
    var newState = Object.assign({}, state);
    action.data.data.products.edges.forEach(product => {
      if (product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIyNDY2ODczNTkwNzI=' ||
          product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQxNjI3MDU5NDg3NjA=') {
        newState = product.node;
      }
    })
    return newState;
  },
  [types.FETCH_SHOPIFY_PRODUCT_FAILURE](state, action) {
    var newState = Object.assign({}, state);
    newState = cbdDefault;
    return newState;
  },
});

const cannabisDefault = {
  "id": "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIyNDY2ODczNTkwNzI=",
  "availableForSale": false,
  "descriptionHtml": "<p class=\"card-text\">Your THC and CBD genetic profile with personalized reports and actionable insights, delivered directly to your smartphone.<br></p>\n<ul class=\"card-text\">\n<li>Metabolism: Understand your sensitivity to THC and CBD</li>\n<li>Mental Health: Explore your short and long-term risk factors associated with THC use</li>\n<li>Memory: Discover how THC can affect your memory</li>\n<li>Product recommendations based on your unique genetic results</li>\n<li>Future updates: Latest research and studies, recommendations and exclusive offers</li>\n</ul>",
  "title": "Cannabis Genetic Test",
  "priceRange": {
    "maxVariantPrice": {
      "amount": "",
      "currencyCode": "CAD"
    }
  },
  "images": {
    "edges": [
      {
        "node": {
          "src": "https://cdn.shopify.com/s/files/1/0085/7525/4624/products/LOBOmonogram_RGB_1_3d1380b0-4496-461e-b79a-f11adaa3e1fb.jpg?v=1564604203"
        }
      }
    ]
  }
}

export const CannabisProduct = createReducer(cannabisDefault, {
  [types.FETCH_SHOPIFY_PRODUCT_SUCCESS](state, action) {
    var newState = Object.assign({}, state);
    action.data.data.products.edges.forEach(product => {
      if (product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzcxMTQ3NzIyNTA4MTY=' ||
          product.node.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY5NTg1NDE1NzAxMzY=') {
        newState = product.node;
      }
    })
    return newState;
  },
  [types.FETCH_SHOPIFY_PRODUCT_FAILURE](state, action) {
    var newState = Object.assign({}, state);
    newState = cannabisDefault;
    return newState;
  },
});
