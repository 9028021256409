import React from "react";
import './AboutUs.scss'
import Helmet from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import aboutus from "../../assets/images/lobo-genetics-card-logo.png";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);

  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>About Us | Lobo Genetics Inc.</title>

          <meta itemProp="name" content="About Us | Lobo Genetics Inc."/>
          <meta itemProp="description"
                content="Lobo Genetics ensures a safer cannabis experience through DNA tests and personalized results."/>
          <meta itemProp="image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="description" content="Lobo Genetics ensures a safer cannabis experience through DNA tests and personalized results."/>
          <meta property="og:title" content="About Us | Lobo Genetics Inc."/>
          <meta property="og:description"
                content="Lobo Genetics ensures a safer cannabis experience through DNA tests and personalized results."/>
          <meta property="og:url" content="https://www.lobogene.com/about.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
          <meta name="twitter:title" content="About Us | Lobo Genetics Inc."/>
          <meta name="twitter:description"
                content="Lobo Genetics ensures a safer cannabis experience through DNA tests and personalized results."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.lobogene.com/images/lobo-genetics-social-logo.png"/>
        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        <section className="bg-img-team mt-0">
          <div className="bg-overlay opacity-9"></div>
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="title text-center">
                  <p className="text-white mb-2">WE ARE LOBO GENETICS</p>
                  <h2 className="text-white">Our mission is to enable a safer cannabis experience through genetics.</h2>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="team">
          <div className="container">
            <div className="row align-items-center">
              <ScrollAnimation animateIn="fadeInRight" animateOnce={true} className="col-md-6 order-md-2 wow ">
                <div className="card-content-text">
                  <div className="title d-inline-block"><h2 className="gradient-text1 mb-3">A personalized cannabis future</h2>
                  <p className="mb-4">Lobo Genetics is a Toronto-based healthcare technology company that was founded in 2018 by a dedicated team of technologists, scientists and entrepreneurs with the goal of helping people make informed choices around cannabis.</p>
                  <p>We believe in helping you better understand your response to cannabis through genetics, data and engagement. Through our proprietary genetic testing platform, consumers can gain rapid genetic insights from a simple cheek swab.</p>
                  {/*
                  <p className="mb-4">Lobo Genetics is
                    a Toronto-based healthcare technology company that was founded in 2018 by a dedicated team of technologists,
                    scientists and entrepreneurs with the goal of helping people make informed choices around cannabis.
                  </p>We believe in helping you better understand your response to cannabis through genetics,
                    data and engagement. Through our proprietary genetic testing platform, the Lobo Cube, consumers can
                    gain rapid genetic insights from a simple cheek swab.
                  </p>
                  */}
                  </div>
                </div>
              </ScrollAnimation>
              <div className="col-md-6">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} className="card-content-image wow hover-effect ">
                  <img src={aboutus} focusable="false" role="img" alt=""/>
                </ScrollAnimation>
              </div>
            </div>


            {/*
              <div className="row text-center no-gutters card-deck pb-0 pb-lg-5 mt-5">
                <div className="col-lg-4 pb-4 pb-lg-5">
                  <div className="card">
                    <div className="card-body px-0 pb-4">
                      <h4 className="card-title mb-3 text-center">John Lem </h4>
                      <h5 className="card-title mb-3 text-center">CHIEF EXECUTIVE OFFICER</h5>
                      <p className="card-text text-left mt-3">John Lem is the co-founder and CEO of Lobo and brings 15
                        years experience in life sciences, medical devices and capital markets. Prior to Lobo, John was
                        the co-founder and VP of Corporate Development of Spartan Bioscience.</p>
                      <p className="card-text text-left mt-3">Previously, John worked at Waterfall Investments,
                        a Toronto-based hedge fund, where he specialized in Canadian and US healthcare investments.
                        Waterfall was acquired by Sentry Investments, a $19-billion asset management company.
                      </p>
                      <p className="card-text text-left alt mt-3">John holds a BASc in computer engineering from the Engineering
                        Science program at the University of Toronto.
                      </p>
                    </div></div>
                  </div>
                <div className="col-lg-4 pb-4 pb-lg-5">
                  <div className="card">
                    <div className="card-body px-0 pb-4">
                      <h4 className="card-title mb-3 text-center">Prakash Gowd</h4>
                      <h5 className="card-title mb-3 text-center">CO-FOUNDER</h5>
                      <p className="card-text text-left mt-3">Prakash Gowd is the co-founder of Lobo and brings 25 years
                        of experience in life sciences, capital markets and the pharmaceutical industry. Prior to Lobo,
                        Prakash was the Executive Director, Health and Biotech Equity Research at CIBC. Previously,
                        Prakash was a corporate development consultant to life science companies, a highly ranked equity
                        research analyst at Canaccord and National Bank Financial, and helped guide new product planning
                        and commercial development at GlaxoSmithKline. He also served as Audit Chair and board director
                        of a public drug development company.</p>
                      <p className="card-text text-left mt-3 alt">Prakash holds an MBA from McGill University, a BSc Pharmacy
                        from the University of British Columbia, and a Chartered Director designation.
                      </p>
                    </div></div>
                </div>
                <div className="col-lg-4 pb-4 pb-lg-5">
                  <div className="card">
                    <div className="card-body px-0 pb-4">
                      <h4 className="card-title mb-3 text-center">Avery Haw</h4>
                      <h5 className="card-title mb-3 text-center">CHIEF FINANCIAL OFFICER</h5>
                      <p className="card-text text-left mt-3">Avery Haw is the CFO of Lobo and brings 15 years of experience
                        in finance and capital markets. Prior to Lobo, Avery was the VP of Investor Relations at Brookfield
                        Renewable Partners where he oversaw communications with the investment community regarding corporate
                        strategy, financial performance and growth initiatives. Previously, Avery was an equity research analyst
                        at TD Securities where he provided research coverage of power, pipeline and utility companies across
                        North America.</p>
                      <p className="card-text text-left mt-3 alt">Avery holds an MBA from the Rotman School of Management
                        and a BASc in computer engineering from the University of Toronto.</p>
                    </div></div>
                </div>
                <div className="col-lg-4 pb-4 pb-lg-5">
                  <div className="card">
                    <div className="card-body px-0 pb-4">
                      <h4 className="card-title mb-3 text-center">Ajay Krishnan</h4>
                      <h5 className="card-title mb-3 text-center">CHIEF TECHNOLOGY OFFICER</h5>
                      <p className="card-text text-left mt-3">Ajay Krishnan is the co-founder, CTO and General Counsel
                        of Lobo, and brings 15 years of experience in technology, law and private equity. Prior to Lobo,
                        Ajay was at Restaurant Brands International (RBI), which oversees the system of 35,000+ restaurants
                        operating globally as Tim Hortons, Burger King and Popeyes. At RBI, Ajay was involved in all aspects
                        of the vertically-integrated supply chain business, and he negotiated a broad range of commercial and
                        technology agreements. Prior to RBI, Ajay worked as a corporate and commercial lawyer in private practice,
                        and as a technology consultant with Infusion Development (acquired by Avanade).</p>
                      <p className="card-text text-left mt-3 alt">Ajay holds a JD from the University of British Columbia
                        and a BASc in computer engineering from the Engineering Science program at the University of Toronto.</p>
                    </div></div>
                </div>
                <div className="col-lg-4 pb-4 pb-lg-5">
                  <div className="card">
                    <div className="card-body px-0 pb-4">
                      <h4 className="card-title mb-3 text-center">Hemanshu Modi</h4>
                      <h5 className="card-title mb-3 text-center">VICE PRESIDENT OF OPERATIONS</h5>
                      <p className="card-text text-left mt-3">Hemanshu Modi is the VP of Operations of Lobo and brings 20
                        years of experience in life sciences and operations. Prior to Lobo, Hemanshu was VP Operations at
                        ACGT, a provider of oligo synthesis and DNA sequencing services. Hemanshu also spent over 10
                        years at Luminex Molecular Diagnostics in various senior roles including Director of Technical
                        Support, Director of Operations and Quality Control, and Director of Design Transfer and Process
                        Engineering.</p>
                      <p className="card-text text-left mt-3 alt">Hemanshu holds a BSc in molecular biology from the
                        University of Waterloo.</p>
                    </div></div>
                </div>
                <div className="col-lg-4 pb-4 pb-lg-5">
                  <div className="card">
                    <div className="card-body px-0 pb-4">
                      <h4 className="card-title mb-3 text-center">Grassfed Ventures</h4>
                      <h5 className="card-title mb-3 text-center">STRATEGIC ADVISOR</h5>
                      <p className="card-text text-left mt-3">Grassfed Ventures is a venture capital and advisory firm
                        focused on the cannabis and life sciences industries. Grassfed was founded by Joseph del Moral,
                        Ronan Levy and Hannan Fleiman, the co-founders of two leading companies in the Canadian medical
                        cannabis industry. Canadian Cannabis Clinics is Canada's largest network of cannabis-specialized
                        medical clinics and CanvasRx is Canada's leading cannabis outreach and counselling service provider.
                        Both were successfully acquired by Aurora Cannabis in 2016.</p>
                    </div></div>
                </div>
              </div>
            */}
          </div>
        </section>

      </>
    )
  }

}

export default AboutUs
